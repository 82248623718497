import { useCallback, useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import {
  MaskedInput as Input,
  FileInput,
  NumberInput,
  Button,
  Loader,
  Modal,
} from '@casanova/casanova-common';
import { ConnectedSelect, DatePickerV2 } from 'components';
import { PaymentType } from '@reservations/components';
import {
  alphaMask,
  hourMask,
  alphaMaskWithSpacesAndAccent,
  userMask,
  alphaMaskWithSpacesAndAccentAndSpecialChars,
} from 'utils/masks';
import { generateCalendarMinimumDate, today2CalendarDate } from 'utils/date';
import { options } from '@casanova/casanova-common/lib/Loader/code';

const minimumDate = generateCalendarMinimumDate(0, 0, 9);
const maximumDate = today2CalendarDate();

const TransferMethod = ({ name, onSelect, hasUpdateDifMetods }) => {
  const buttonGuardarBancoStyle = {
    backgroundColor: '#450E99',
    color: 'white',
    padding: '15px 32px',
    textAlign: 'center',
    textDecoration: 'none',
    display: 'inline-block',
    fontSize: '16px',
    margin: '4px 2px',
    marginTop: '10px',
    cursor: 'pointer',
    border: 'none',
    borderRadius: '4px',
  };

  const { values, setFieldValue } = useFormikContext();

  const [otroBanco, setOtroBanco] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [selectedBank, setSelectedBank] = useState('');
  const [show, setShow] = useState(false);
  const [titleModalBancoNuevo, setTitleModalBancoNuevo] = useState('');
  const [subtitleModalBancoNuevo, setSubtitleModalBancoNuevo] = useState('');

  const obtenerFechaActual = () => {
    const fechaActual = new Date();
    const year = fechaActual.getFullYear();
    const month = String(fechaActual.getMonth() + 1).padStart(2, '0'); // Los meses comienzan desde 0
    const day = String(fechaActual.getDate()).padStart(2, '0');
    const fechaFormateada = `${year}-${month}-${day}`;
    return fechaFormateada;
  };

  const obtenerHoraActual = () => {
    const horaActual = new Date();
    const horas = String(horaActual.getHours()).padStart(2, '0');
    const minutos = String(horaActual.getMinutes()).padStart(2, '0');
    const segundos = String(horaActual.getSeconds()).padStart(2, '0');
    const horaFormateada = `${horas}:${minutos}:${segundos}`;
    return horaFormateada;
  };

  useEffect(() => {
    setFieldValue('transferDate', obtenerFechaActual());
    setInterval(() => {
      setFieldValue('transferHour', obtenerHoraActual());
    }, 1000);
  }, []);

  const handleChangeBanco = (e) => {
    setSelectedBank(e.target.value);
    console.log(selectedBank);
    if (e.target.value == 'c1f56866-0904-499b-a3fe-1b70a3e207b3') {
      setOtroBanco(true);
      return;
    }
    setOtroBanco(false);
  };

  const handleAddBank = async (newBank, options = {}) => {
    let uuidNuevoBanco = '';
    try {
      const headers = options.headers || new Headers();

      const { authToken } = options;
      if (authToken) {
        headers.append('Authorization', `Bearer ${authToken}`);
        headers.append('Content-Type', 'application/json');
      }
      headers.append('Content-Type', 'application/json');

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/pricequotes/v1/catalogs/banks`,
        {
          method: 'POST',
          body: JSON.stringify(newBank),
          headers,
          credentials: 'include',
        }
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      // actualizarSelectBancos();
      console.log('Banco agregado exitosamente:', data);

      uuidNuevoBanco = data.uuid;
      setSelectedBank(data.uuid);
      setShowLoader(false);
      setTitleModalBancoNuevo('Banco agregado');
      setSubtitleModalBancoNuevo('Selecciona el banco que has agregado.');
      setShow(true);
    } catch (error) {
      setShowLoader(false);
      setTitleModalBancoNuevo('Error');
      setSubtitleModalBancoNuevo(
        'El banco que intentas agregar ya existe o vuelve a intentarlo más tarde.'
      );
      setShow(true);
    }
    setFieldValue('holdbackBank', uuidNuevoBanco);
  };

  const handleSaveNewBank = () => {
    setShowLoader(true);
    setOtroBanco(false);
    const newBank = {
      name: values.holdbackOtherBankName,
      legalName: values.holdbackOtherBankName,
    };
    handleAddBank(newBank, options);
  };

  const handleCloseModal = useCallback(() => {
    setShow(false);
  }, [setShow]);

  return (
    <PaymentType
      name={name}
      onSelect={onSelect}
      hasUpdateDifMetods={hasUpdateDifMetods}
      title="TRANSFERENCIA ELECTRÓNICA"
    >
      {showLoader && <Loader show />}
      <Modal
        open={show}
        header={null}
        footer={null}
        className="ViewRefundDetail"
        contentClassName="px-4 py-4 container"
        closeButton
        onClose={handleCloseModal}
        bigModal
      >
        <div className="ml-4">
          <h3 className="text-primary">{titleModalBancoNuevo}</h3>
          <p>{subtitleModalBancoNuevo}</p>
        </div>
      </Modal>
      <div className="row">
        <div className="col-6">
          <ConnectedSelect
            label="Banco EMISOR DEL PAGO*"
            name="bankTo"
            value={selectedBank}
            options="commons.catalogsBanks"
            onChange={handleChangeBanco}
          />
        </div>
        {otroBanco && (
          <>
            <div className="col-md-6 col-sm-12">
              <Input
                mask={alphaMaskWithSpacesAndAccent(40)}
                maskPlaceholder=""
                label="Otro Banco*"
                placeholder="Ingresa nuevo banco*"
                name="holdbackOtherBankName"
                disabled={!otroBanco}
              />
            </div>
            <div className="col-md-6 col-sm-12 d-flex align-items-center">
              <Button
                style={buttonGuardarBancoStyle}
                onClick={() => handleSaveNewBank()}
              >
                Guardar Banco
              </Button>
            </div>
          </>
        )}
        <div className="col-6">
          <Input
            mask={alphaMaskWithSpacesAndAccentAndSpecialChars(50)}
            maskPlaceholder=""
            label="CLAVE DE RASTREO*"
            placeholder="CLAVE DE RASTREO*"
            name="bankClave"
          />
        </div>
        <div className="col-6">
          <Input
            mask={alphaMaskWithSpacesAndAccentAndSpecialChars(50)}
            maskPlaceholder=""
            label="NO. DE REFERENCIA*"
            placeholder="NO. DE REFERENCIA*"
            name="bankReference"
          />
        </div>
        <div className="col-6">
          <DatePickerV2
            initialValue={values.transferDate}
            label="Fecha*"
            name="transferDate"
            placeholder="Fecha*"
            minimumDate={minimumDate}
            maximumDate={maximumDate}
            disabled
          />
        </div>
        <div className="col-6">
          <Input
            mask={hourMask}
            maskPlaceholder=""
            label="HORA*"
            placeholder="Hora*"
            name="transferHour"
            disabled
          />
        </div>
        <div className="col-md-6">
          <FileInput
            url={values.transferVoucher}
            displayText={values.transferVoucher}
            type="file"
            label="Comprobante*"
            placeholder="Comprobante*"
            name="transferVoucher"
            showIcon={false}
          />
        </div>
        <div className="col-6">
          {hasUpdateDifMetods && (
            <NumberInput
              decimals={2}
              max={9_999_999}
              label="Monto en Transferencia"
              placeholder="Monto en Transferencia"
              name="amountTransfer"
              disabled={!hasUpdateDifMetods}
            />
          )}
        </div>
      </div>
    </PaymentType>
  );
};

TransferMethod.propTypes = {
  name: PropTypes.string,
  onSelect: PropTypes.func,
  hasUpdateDifMetods: PropTypes.bool,
};

TransferMethod.defaultProps = {
  name: 'transfer',
};

export default TransferMethod;
