import React, { useEffect, useCallback, useState } from 'react';
import * as XLSX from 'xlsx';
import { Table, Button } from '@casanova/casanova-common';
import i18n from '@i18n';
import { SHOW_DROPDOWN_MODE } from '@casanova/casanova-common/lib/utils/constants/index.js';
import { TableBottom } from 'components';
import { withForm } from 'hooks';
import CloseLoan from '@loans/CloseLoan';
import CancelLoan from '@loans/CancelLoan';
import AuthorizeLoan from '@loans/AuthorizeLoan';
import {
  getLoanContextualMenuItems,
  contextualMenu,
} from '@loans/common/config';
import { transformDropdownElements } from '@casanova/casanova-common/lib/utils/transformHelpers';
import { CREATE_LOAN_REQUEST } from 'utils/roles/permissions/loansPermissions';
import useCleanModule from 'hooks/useCleanModule';
import columns, { showDropdownCell } from './columns';
import { config } from './config';

import { getLoansList } from 'services/loans';

function ListOfLoans({
  loans,
  getLoans,
  setListPage,
  history,
  fetchCatalog,
  onUpdateForm,
}) {
  const { params, searchPattern, filters } = loans;

  const [showCancel, setShowCancel] = useState(false);
  const [hideCancel, setHideCancel] = useState(false);
  const [showClose, setShowClose] = useState(false);
  const [hideClose, setHideClose] = useState(false);
  const [showAuthorize, setShowAuthorize] = useState(false);
  const [hideAuthorize, setHideAuthorize] = useState(false);
  const [selectedLoan, setSelectedLoan] = useState('');

  useCleanModule({ module: 'loans' });

  const handleAddClick = useCallback(() => {
    history.push('/loans/add');
  }, [history]);

  const handleDropdownSelect = useCallback(
    (section) => {
      const { name = '', uuid = '' } = section;
      setSelectedLoan(uuid);

      setHideCancel(true);
      setHideClose(true);
      setHideAuthorize(true);

      switch (name) {
        case contextualMenu.CANCEL.name:
          setShowCancel(true);
          setHideCancel(false);
          break;
        case contextualMenu.CLOSE.name:
          setShowClose(true);
          setHideClose(false);
          break;
        case contextualMenu.AUTHORIZE.name:
          setShowAuthorize(true);
          setHideAuthorize(false);
          break;
        case contextualMenu.EDIT.name:
          history.push(`/loans/${uuid}/edit`);
          break;
        default:
          break;
      }
    },
    [history]
  );

  const changeTitles = (payload) =>
    payload.map((el) => ({
      Id: el.folio,
      Placa: el.plateNumber || 'Sin información',
      Observaciones: el.observations || 'Sin información',
      'Fecha de salida': el.departureDate || 'Sin información',
      'Hora de salida': el.departureTime || 'Sin información',
      'Fecha de regreso': el.returnDate || 'Sin información',
      'Hora de regreso': el.returnTime || 'Sin información',
      Usuario: el.requesterName || 'Sin información',
      Conductor: el.driverName || 'Sin información',
      Estatus: el.loanStatus.name || 'Sin información',
    }));

  const getInformation = async () =>
    new Promise((resolve) => {
      Promise.all([
        getLoansList({
          params: { ...params, page: 0, size: loans.totalElements },
        }),
      ]).then((res) => {
        const result = [...res[0].content];
        resolve(result);
      });
    });

  const exportExcelInforation = async () => {
    const results = await getInformation();
    const worksheet = XLSX.utils.json_to_sheet(changeTitles(results));
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Lista de préstamos');
    const date = new Date();
    XLSX.writeFile(workbook, `Prestamos-${date.toLocaleDateString()}.xlsx`, {
      compression: true,
    });
  };

  const handleRowClick = useCallback(
    ({ uuid }) => {
      history.push(`/loans/${uuid}/detail`);
    },
    [history]
  );

  useEffect(() => {
    fetchCatalog({
      catalogId: 'catalogs/loan-statuses',
      catalogPath: 'vehicle',
    });
  }, [fetchCatalog]);

  useEffect(() => {
    getLoans({ params, filters, searchPattern });
  }, [getLoans, params, filters, searchPattern]);

  useEffect(() => {
    onUpdateForm(params);
  }, [onUpdateForm, params]);

  return (
    <>
      <div className="col mb-3 d-flex align-items-center justify-content-end">
        <Button
          outline
          block
          className="mr-1 w-25 mt-0"
          onClick={() => exportExcelInforation()}
        >
          Excel
        </Button>
      </div>
      {!hideCancel && (
        <CancelLoan
          uuid={selectedLoan}
          show={showCancel}
          setShow={setShowCancel}
        />
      )}
      {!hideClose && (
        <CloseLoan
          uuid={selectedLoan}
          show={showClose}
          setShow={setShowClose}
        />
      )}
      {!hideAuthorize && (
        <AuthorizeLoan
          uuid={selectedLoan}
          show={showAuthorize}
          setShow={setShowAuthorize}
        />
      )}
      <Table
        additionalHeader
        rowClassName="row-clickable"
        emptyMessage={i18n('ERROR__COMMONS__NOT_FOUND')}
        rowClick={handleRowClick}
        showDropdownMode={SHOW_DROPDOWN_MODE.POINTS_CLICK}
        dataList={loans.results}
        columns={columns.map((col) => ({
          ...col,
          renderer: (props = { data: {} }) => {
            const { loanStatus = {} } = props.data;
            const Component = col.renderer;
            return (
              <Component
                {...props}
                dropdownOptions={transformDropdownElements({
                  elements: getLoanContextualMenuItems(loanStatus.identifier),
                })}
              />
            );
          },
        }))}
        dropdownCell={showDropdownCell}
        onDropdownSelect={handleDropdownSelect}
        withEllipsis
      />
      <TableBottom
        search={loans}
        onChange={setListPage}
        onClick={handleAddClick}
        textButton={i18n('LOANS__LIST_OF_LOANS__NEW_LOAN')}
        actionPermission={CREATE_LOAN_REQUEST}
      />
    </>
  );
}

export default withForm({ config })(ListOfLoans);
