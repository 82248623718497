import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';
import { useState, useEffect } from 'react';

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#FFFFFF',
    padding: 20,
  },
  section: {
    margin: 10,
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
  },
  title: {
    fontSize: 20,
    marginBottom: 10,
    fontWeight: 'bold',
  },
  subtitle: {
    fontSize: 16,
    marginTop: 10,
    marginBottom: 5,
    fontWeight: 'bold',
  },
  text: {
    fontSize: 12,
  },
  listItem: {
    fontSize: 12,
    marginLeft: 10,
  },
  image: {
    width: 150,
    height: 100,
    marginTop: 5,
    marginBottom: 5,
  },
  urlText: {
    fontSize: 10,
    color: 'blue',
    marginLeft: 10,
    textDecoration: 'underline',
    wordBreak: 'break-all', // Evita cortes inesperados
  },
});

// **Función para convertir imagen URL a Base64**
const fetchImageAsBase64 = async (url) => {
  try {
    const response = await fetch(url);
    // const response = await axios.get(url, { responseType: 'blob' });
    if (!response.ok) {
      console.error(`Error al descargar la imagen: ${url}`);
      return null;
    }

    const blob = await response.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  } catch (error) {
    console.error('Error al procesar la imagen:', error);
    return null;
  }
};

const MyDocument = ({ reservationInfo, verificationCheckList = {} }) => {
  const vehicle = reservationInfo?.selectedVehicleLinePrice?.vehicle || {};
  const verificationGroups = verificationCheckList?.verificationGroups || [];
  const [imageMap, setImageMap] = useState({});
  // console.log('verificationGroups:', verificationGroups);
  // console.log('reservationInfo:', reservationInfo);
  // console.log('vehicle:', vehicle);
  // console.log('verificationCheckList:', verificationCheckList);
  // console.log('imageMap:', imageMap);

  useEffect(() => {
    const loadImages = async () => {
      const newImageMap = {};

      for (const group of verificationGroups) {
        if (group?.verificationPoints) {
          for (const point of group.verificationPoints) {
            if (point.images?.length > 0) {
              for (const img of point.images) {
                if (img.presignedUrl && !newImageMap[img.presignedUrl]) {
                  const base64 = await fetchImageAsBase64(img.presignedUrl);
                  if (base64) {
                    newImageMap[img.presignedUrl] = base64;
                  }
                }
              }
            }
          }
        }
      }

      setImageMap(newImageMap);
    };

    loadImages();
  }, [verificationGroups]);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.title}>Detalles de la Reserva</Text>
          <Text style={styles.text}>
            Folio: {reservationInfo?.folio || 'N/A'}
          </Text>
          <Text style={styles.text}>
            Estatus: {reservationInfo?.status || 'N/A'}
          </Text>

          <Text style={styles.text}>
            Vehículo Asignado:{' '}
            {reservationInfo?.assignedVehicle?.brand || 'N/A'}{' '}
            {reservationInfo?.assignedVehicle?.line || ''}
          </Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.title}>Verificación del Vehículo</Text>
          <Text style={styles.text}>
            Total de Grupos de Verificación: {verificationGroups.length}
          </Text>

          {verificationGroups.length > 0 ? (
            verificationGroups.map((group, index) => (
              <View key={index} style={styles.section}>
                <Text style={styles.subtitle}>
                  {group?.name || 'Grupo sin nombre'}
                </Text>

                {Array.isArray(group?.verificationPoints) &&
                group.verificationPoints.length > 0 ? (
                  group.verificationPoints.map((point, pointIndex) => (
                    <View key={pointIndex} style={{ marginLeft: 10 }}>
                      <Text style={styles.listItem}>- {point.name}</Text>

                      {Array.isArray(point.images) &&
                      point.images.length > 0 ? (
                        point.images.map((img, imgIndex) => (
                          // console.log('img:', img);
                          // console.log('imageMap[img.presignedUrl]:', imageMap[img.presignedUrl]);
                          <View key={imgIndex}>
                            {/* <Text style={styles.urlText}>
                              {img.presignedUrl}
                            </Text> */}
                            {/* <Text>
                              {imageMap[img.presignedUrl]}
                            </Text> */}
                            {imageMap[img.presignedUrl] ? (
                              <Image
                                src={imageMap[img.presignedUrl]}
                                style={styles.image}
                              />
                            ) : (
                              <Text style={styles.listItem} />
                            )}
                          </View>
                        ))
                      ) : (
                        <Text style={styles.listItem}>
                          No hay imágenes disponibles
                        </Text>
                      )}
                    </View>
                  ))
                ) : (
                  <Text style={styles.listItem}>
                    No hay puntos de verificación
                  </Text>
                )}
              </View>
            ))
          ) : (
            <Text style={styles.text}>No hay verificaciones registradas.</Text>
          )}
        </View>
      </Page>
    </Document>
  );
};

export default MyDocument;
