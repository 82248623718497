import React, { useCallback, useEffect, useRef, useState } from 'react';
import _get from 'lodash/get';
import { useFormikContext } from 'formik';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { utils } from 'react-modern-calendar-datepicker';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import _noop from 'lodash/noop';
import {
  Icons,
  MaskedInput as Input,
  ConnectedSelect,
  Select,
  DatePickerV2,
} from '@casanova/casanova-common';
import {
  numericMask,
  hourMask,
} from '@casanova/casanova-common/lib/utils/masks';
import { transformDisableSelectOption } from '@casanova/casanova-common/lib/utils/transformHelpers';
import {
  setStartAvailableHours,
  setEndAvailableHours,
  setEndLimitHours,
} from 'store/reservations/actions';
import { setRentalPlansToUse } from 'store/dailyRentContracts/actions';
import {
  formattedDateToPickerV2,
  isToday,
  YYYY_MM_DD,
  HH_MM,
} from 'utils/date';
import { FORM_ACTIONS } from 'utils/constants/formActions';
import { RENTAL_PLANS } from '@reservations/commons/constants';
import { getReturnDateTime } from './config';
import './ReservationDates.scss';

const { VIEW, EDIT } = FORM_ACTIONS;

const ReservationDates = ({
  submitted,
  action,
  onUpdateForm,
  className,
  selectedCategory,
  onDirtyAndSubmittedChange = _noop,
  onErrorsChange = _noop,
  onValuesChange = _noop,
  alwaysDirty = false,
  notPriceQuote = false,
}) => {
  const [planTypesToDisabled, setPlanTypesToDisabled] = useState([]);
  const [
    isFirstTimeBranchOrTimeChanged,
    setIsFirstTimeBranchOrTimeChanged,
  ] = useState({ branch: true, time: true });

  const [shouldUpdateEndHour, setShouldUpdateEndHour] = useState(true);

  const {
    values,
    dirty,
    initialValues,
    errors,
    validateForm,
    setFieldTouched,
    setFieldValue,
    handleSubmit,
  } = useFormikContext();

  const automaticReturnDate = false;

  const dispatch = useDispatch();

  const quoteValues = useSelector((state) =>
    _get(state, 'reservations.quote.values', {})
  );

  const availableTime = useSelector((state) =>
    _get(state, 'reservations.quote.availableTime.list', [])
  );

  const branches = useSelector((state) =>
    _get(state, 'commons.branches.list', [])
  );

  const rentalPlans = useSelector((state) =>
    _get(state, 'dailyRentContracts.rentalPlans.list', [])
  );

  const rentalPlansToUse = useSelector((state) =>
    _get(state, 'dailyRentContracts.rentalPlans.toUse.list', [])
  );

  const availableStartHours = useSelector((state) =>
    _get(state, 'reservations.quote.selectedDates.start.list', [])
  );

  const [chipReservationDays, setChipReservationDays] = useState(0);
  const [dailyRentBranches, setDailyRentBranches] = useState([]);

  const handleDaysNumberChange = (evt, currentValues = {}) => {
    const daysNumber = evt?.target?.value || values.daysNumber;
    const dateStart = currentValues?.dateStart || values.dateStart;
    const startHour = currentValues?.startHour || values.startHour;

    if (!daysNumber || !dateStart || !startHour) return;

    const returnDateTime = getReturnDateTime({
      daysNumber,
      dateStart,
      startHour,
    });

    const endDateMoment = moment(returnDateTime);
    const dateEnd = endDateMoment.format(YYYY_MM_DD);
    const endHour = endDateMoment.format(HH_MM);

    setFieldValue('dateEnd', dateEnd);
    setFieldValue('endHour', endHour);
    setFieldValue('returnDateTime', returnDateTime);
  };

  useEffect(() => {
    if (branches <= 0) return;
    setDailyRentBranches(
      branches.filter(
        (b) =>
          b.uuid === '84461aa3-28f4-4496-8472-0a483a14277a' ||
          b.uuid === 'ff515133-ef70-45e0-8f7e-7659dbcb9c32' ||
          b.uuid === 'c589db6b-73f7-4840-8375-89e8bfedb01b' ||
          b.uuid === '29e168fc-86a6-43ea-b4c6-9e50a5330bb4' ||
          b.uuid === '8349bc4b-7a41-4e10-bf04-7501d937a815'
      )
    );
  }, [branches]);

  const obtenerHoraActual = () => {
    const horaActual = moment(new Date()).add(2, 'm');
    const horas = String(horaActual.hour()).padStart(2, '0');
    const minutos = String(horaActual.minute()).padStart(2, '0');
    const horaFormateada = `${horas}:${minutos}`;
    return horaFormateada;
  };

  useEffect(() => {
    if (!notPriceQuote) return;

    setFieldValue('returnDateError', errors.dateEnd || '');
  }, [errors.dateEnd, notPriceQuote]);

  useEffect(() => {
    if (notPriceQuote && values.vehicles.length > 0)
      handleDateStartChange(moment(new Date()).format('YYYY-M-D'));
  }, [notPriceQuote, values.vehicles]);

  useEffect(() => {
    if (notPriceQuote) {
      console.log('check:', notPriceQuote);
      setInterval(() => setFieldValue('startHour', obtenerHoraActual()), 1000);
    }
  }, []);

  const touchDates = useCallback(() => {
    ['dateStart', 'dateEnd'].forEach((field) => {
      setFieldTouched(field, true);
    });
  }, [setFieldTouched]);

  const setBranchEnd = useCallback(
    (branchUuid) => {
      if (branches.length > 0 && branchUuid) {
        const { name = null, uuid = null } =
          branches.find((branch) => branch.uuid === branchUuid) || {};

        if (uuid && name) {
          setFieldValue('branchEnd', uuid);
          setFieldValue('branchEndName', name);
        }
      }
    },
    [branches]
  );

  const handleDateEndChange = useCallback(
    (value) => {
      if (value && value !== '' && dirty) {
        setFieldTouched('dateEnd', true);

        if (!automaticReturnDate) {
          setFieldValue('endHour', '');
        }
      }
      if (notPriceQuote) setFieldValue('dateEnd', value);
      else handleChipResevationNumber();
      // eslint-disable-next-line no-shadow
      validateForm(values).then((errors) => {
        const notErrors = !errors || (errors && !errors.dateEnd);
        const canSetAvailableDates = dirty && notErrors;
        if (canSetAvailableDates && !notPriceQuote) {
          dispatch(setEndAvailableHours(value));
        }
      });
    },
    [
      dirty,
      dispatch,
      validateForm,
      values,
      setEndAvailableHours,
      setFieldTouched,
      setFieldValue,
      notPriceQuote,
      automaticReturnDate,
    ]
  );

  const handleDateStartChange = useCallback(
    (value) => {
      if (value && value !== '' && dirty) {
        setFieldTouched('dateStart', true);
        setFieldValue('startHour', '');
      }

      // eslint-disable-next-line no-shadow
      validateForm({ ...values, dateStart: value }).then((errors) => {
        const canSetAvailableDates =
          dirty && (!errors || (errors && !errors.dateStart));
        if (canSetAvailableDates) {
          const now = moment(new Date(), `${YYYY_MM_DD} ${HH_MM}`);

          const remainder = 30 - (now.minute() % 30);

          const limitStart = isToday(value)
            ? moment(now).add(remainder, 'minutes').format(HH_MM)
            : null;

          dispatch(setStartAvailableHours({ value, limitStart }));
          if (notPriceQuote) {
            setTimeout(
              () => handleDaysNumberChange(null, { dateStart: value }),
              1
            );
            const startDate = moment(value, 'YYYY-M-D');
            const temporalStartDate = moment(
              rentalPlans[0]?.temporalStartDate,
              'YYYY-M-D'
            );
            const temporalEndDate = moment(
              rentalPlans[0]?.temporalEndDate,
              'YYYY-M-D'
            );
            const isTemporal = startDate.isBetween(
              temporalStartDate,
              temporalEndDate
            );
            dispatch(
              setRentalPlansToUse(
                rentalPlans?.filter((rp) =>
                  isTemporal
                    ? rp.name.includes('Temporal')
                    : !rp.name.includes('Temporal')
                )
              )
            );
          } else handleChipResevationNumber();
        }
      });
    },
    [
      dirty,
      dispatch,
      validateForm,
      values,
      setStartAvailableHours,
      setFieldTouched,
      setFieldValue,
      notPriceQuote,
      handleDaysNumberChange,
      setRentalPlansToUse,
    ]
  );

  const handleChipResevationNumber = useCallback(() => {
    const star = moment(values.dateStart, 'YYYY-M-D');
    const end = moment(values.dateEnd, 'YYYY-M-D');
    const daysDiff =
      isNaN(end.diff(star, 'days')) || end.diff(star, 'days') < 0
        ? 0
        : end.diff(star, 'days');
    setChipReservationDays(daysDiff);
  }, [values.dateStart, values.dateEnd]);

  const handleChangeDateStart = useCallback(
    (evt) => {
      const branchStart = evt.target.value;
      if (isFirstTimeBranchOrTimeChanged.branch) setBranchEnd(branchStart);
      // setFieldValue('branchEnd', branchStart);
    },
    [branches, isFirstTimeBranchOrTimeChanged]
  );

  const minimumDateEnd = useCallback(() => {
    if (values.dateStart) {
      const formatedDate = formattedDateToPickerV2(values.dateStart);
      return formatedDate;
    }
    return utils().getToday();
  }, [values.dateStart]);

  const onStartHourChange = (evt) => {
    const { value } = evt.target;
    const hour = value.split(' ')[1];
    const mintsOfHour = hour && Number(hour.substr(3, 3));
    const naturalHour = hour && Number(hour.substr(0, 2));
    const increiseHour = naturalHour + 1;
    const startHour = `${increiseHour}:${mintsOfHour}`;

    if (isFirstTimeBranchOrTimeChanged.time) {
      const formatedDateEnd = moment(values.dateEnd);
      setFieldValue('endHour', `${formatedDateEnd.format(YYYY_MM_DD)} ${hour}`);
    }

    if (startHour) {
      dispatch(setEndLimitHours({ limitStart: startHour, limitEnd: null }));
    }

    if (values.dateEnd && !automaticReturnDate) {
      dispatch(setEndAvailableHours(values.dateEnd));
    }

    if (notPriceQuote)
      setTimeout(() => handleDaysNumberChange(null, { startHour: value }), 1);
  };

  useEffect(() => {
    if (alwaysDirty) {
      setTimeout(() => {
        touchDates();
      }, [10]);
    }
  }, [touchDates, handleSubmit]);

  useEffect(() => {
    if (selectedCategory) {
      touchDates();
    }
  }, [touchDates, selectedCategory]);

  useEffect(() => {
    onDirtyAndSubmittedChange({ dirty, submitted });
  }, [dirty, submitted, onDirtyAndSubmittedChange]);

  useEffect(() => {
    onErrorsChange(errors);
  }, [errors]);

  useEffect(() => {
    onValuesChange(values);
  }, [onValuesChange, values]);

  // useEffect(() => {
  //   const validData = Boolean(quoteValues.pickupBranch);
  //   if (validData) setBranchEnd(quoteValues.pickupBranch);
  // }, [quoteValues, setBranchEnd]);

  useEffect(() => {
    if (action === EDIT) {
      onUpdateForm({ ...quoteValues, availableTime });
      setBranchEnd(quoteValues.returnBranch);
    } else if (
      notPriceQuote &&
      availableTime.length > 0 &&
      (!values.availableTime || values?.availableTime?.length === 0)
    ) {
      setFieldValue('availableTime', availableTime);
    }
  }, [quoteValues, action, availableTime, setBranchEnd, values.availableTime]);

  useEffect(() => {
    if (selectedCategory) {
      setFieldValue('vehicleCategory', selectedCategory);
    }
  }, [setFieldValue, selectedCategory]);

  useEffect(() => {
    const daysNumber = Number(values.daysNumber);
    if (daysNumber > 0 && rentalPlansToUse.length > 0) {
      const { TWO_HUNDRED_FIFTY_KM, FREE_KM, WEEKLY, MONTHLY } = RENTAL_PLANS;

      const isTwoHundredFiftyPlan = daysNumber === 1;

      if (isTwoHundredFiftyPlan) {
        const twoHundredFiftyKmPlan = rentalPlansToUse.find(({ name }) =>
          name.includes(TWO_HUNDRED_FIFTY_KM)
        );
        if (twoHundredFiftyKmPlan) {
          setFieldValue('planType', twoHundredFiftyKmPlan.uuid);
          setPlanTypesToDisabled(rentalPlansToUse);
        }
      }

      const isFreeKmPlan = daysNumber > 1 && daysNumber < 7;

      if (isFreeKmPlan) {
        const freeKmPlan = rentalPlansToUse.find(({ name }) =>
          name.includes(FREE_KM)
        );

        if (freeKmPlan) {
          setFieldValue('planType', freeKmPlan.uuid);

          const newDisabledPlans = rentalPlansToUse.filter(
            ({ name }) =>
              !name.includes(FREE_KM) && !name.includes(TWO_HUNDRED_FIFTY_KM)
          );
          setPlanTypesToDisabled(newDisabledPlans);
        }
      }

      const isWeeklyPlan = daysNumber > 6 && daysNumber < 30;

      if (isWeeklyPlan) {
        const weeklyPlan = rentalPlansToUse.find(({ name }) =>
          name.includes(WEEKLY)
        );

        if (weeklyPlan) {
          setFieldValue('planType', weeklyPlan.uuid);

          const newDisabledPlans = rentalPlansToUse.filter(({ name }) =>
            name.includes(MONTHLY)
          );
          setPlanTypesToDisabled(newDisabledPlans);
        }
      }

      const isMonthlyPlan = daysNumber > 29;

      if (isMonthlyPlan) {
        const monthlyPlan = rentalPlansToUse.find(({ name }) =>
          name.includes(MONTHLY)
        );

        if (monthlyPlan) {
          setFieldValue('planType', monthlyPlan.uuid);
          setPlanTypesToDisabled(rentalPlansToUse);
        }
      }
    }
  }, [values.daysNumber, rentalPlansToUse, setFieldValue]);

  const componentCls = classNames('ReservationDates', className);

  useEffect(() => {
    // Actualiza la hora de devolución cuando cambia la hora de inicio y la opción automática está deshabilitada
    if (
      !automaticReturnDate &&
      values.startHour &&
      isFirstTimeBranchOrTimeChanged.time
    ) {
      const formatedDateEnd = moment(values.dateEnd);
      setFieldValue(
        'endHour',
        formatedDateEnd.format(YYYY_MM_DD) +
          ' ' +
          values.startHour.split(' ')[1]
      );
    }
  }, [values.dateEnd]);

  return (
    <div className={componentCls}>
      {notPriceQuote ? (
        <>
          <div className="row">
            <div className="col-1 date-icon">
              <Icons.SchedStartVehicle width="4.5rem" height="3rem" />
            </div>
            <div className="col-4">
              <DatePickerV2
                id="dailyRentContracts_contractQuote_startDate_dateInput"
                initialValue={initialValues.dateStart}
                placeholder="Fecha de salida*"
                onChange={handleDateStartChange}
                name="dateStart"
                label="Fecha de salida*"
                disabled="true"
                autoComplete="off"
              />
            </div>
            <div className="col-2">
              <Input
                id="dailyRentContracts_contractQuote_startHour_input"
                label="Hora*"
                placeholder="Hora*"
                name="startHour"
                disabled="true"
              />
            </div>
            <div className="col-2">
              <Input
                id="dailyRentContracts_contractQuote_daysNumber_input"
                label="N. de días*"
                placeholder="N. de días*"
                name="daysNumber"
                mask={numericMask(3)}
                maskPlaceholder=""
                onChange={handleDaysNumberChange}
              />
            </div>
            <div className="col-3">
              <ConnectedSelect
              id="dailyRentContracts_contractQuote_planType_connectedSelect"
                label="Tipo de plan*"
                name="planType"
                options="dailyRentContracts.rentalPlans.toUse"
                disabled={
                  planTypesToDisabled.length === rentalPlansToUse.length
                }
                transformer={(options) =>
                  transformDisableSelectOption(options, planTypesToDisabled)
                }
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="row">
            <div className="col-2 date-icon">
              <Icons.SchedStartVehicle width="4.5rem" height="3rem" />
            </div>
            <div className="col-4">
              <DatePickerV2
                id="dailyRentContracts_contractQuote_startDate_dateInput"
                initialValue={initialValues.dateStart}
                placeholder="Fecha de salida*"
                onChange={handleDateStartChange}
                name="dateStart"
                label="Fecha de salida*"
                disabled={action === VIEW}
                autoComplete="off"
              />
            </div>
            <div className="col-2">
              <ConnectedSelect
                label="Hora*"
                name="startHour"
                options="reservations.quote.selectedDates.start"
                onChange={onStartHourChange}
              />
            </div>
            <div className="col-4">
              <Select
                id="dailyRentContracts_contractQuote_startBranch_select"
                disabled={action === VIEW}
                label="Sucursal de salida*"
                name="branchStart"
                options={dailyRentBranches}
                selectProps={{ fullKey: true, availableFirstValue: true }}
                onChange={handleChangeDateStart}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-2 date-icon">
              <Icons.SchedEndVehicle width="4.5rem" height="3rem" />
            </div>
            <div className="col-4">
              <DatePickerV2
                id="dailyRentContracts_contractQuote_endDate_dateInput"
                initialValue={initialValues.dateEnd}
                placeholder="Fecha de devolución*"
                onChange={handleDateEndChange}
                name="dateEnd"
                label="Fecha de devolución*"
                disabled={action === VIEW || !values.dateStart}
                autoComplete="off"
                minimumDate={minimumDateEnd()}
              />
            </div>
            <div className="col-2">
              {automaticReturnDate ? (
                <Input
                  id="dailyRentContracts_contractQuote_endHour_input"
                  placeholder="Hora*"
                  label="Hora*"
                  name="endHour"
                  value={
                    values.startHour &&
                    availableStartHours.find((item) => {
                      return item.value === values.startHour;
                    })?.label
                  }
                  disabled
                />
              ) : (
                <ConnectedSelect
                  label="Hora*"
                  name="endHour"
                  options="reservations.quote.selectedDates.end"
                  disabled={!values.startHour}
                  onChange={() =>
                    setIsFirstTimeBranchOrTimeChanged((prev) => ({
                      ...prev,
                      time: false,
                    }))
                  }
                />
              )}
            </div>

            <div className="col-4">
              <Select
                id="dailyRentContracts_contractQuote_endBranch_select"
                disabled={automaticReturnDate || action === VIEW}
                label="Sucursal de devolución*"
                name="branchEnd"
                options={dailyRentBranches}
                selectProps={{ fullKey: true, availableFirstValue: true }}
                onChange={() =>
                  setIsFirstTimeBranchOrTimeChanged((prev) => ({
                    ...prev,
                    branch: false,
                  }))
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="col-4 offset-2 align-self-center mt-4">
              <p className="text-primary-500 text-lg font-semibold">
                Total d&iacute;as de renta del veh&iacute;culo:{' '}
                {chipReservationDays}
              </p>
            </div>
            {/* <div className="col-3 offset-2">
              <Input
                mask={numericMask(4)}
                maskPlaceholder=""
                label="Cantidad de vehículos*"
                placeholder="Cantidad de vehículos*"
                name="vehicleQuantity"
                disabled
                // onBlur={handleValidateCellphone}
                // disabled={isView}
              />
            </div> */}
          </div>
        </>
      )}
    </div>
  );
};

ReservationDates.propTypes = {
  action: PropTypes.oneOf(['add', 'edit', 'view']),
  notPriceQuote: PropTypes.bool,
};

ReservationDates.defaultProps = {
  action: 'add',
  notPriceQuote: false,
};

export default ReservationDates;
