/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import _get from 'lodash/get';
import { useFormikContext } from 'formik';
import {
  RadioGroupInput,
  MaskedInput as Input,
  FileInput,
  Switch,
  Icons,
  DatePickerV2,
  ConnectedSelect,
  NumberInput,
  Button,
} from '@casanova/casanova-common';
import { PaymentType } from '@reservations/components';
import {
  alphaMask,
  numericMask,
  hourMask,
  alphaMaskWithSpacesAndAccent,
  cardNumberValidations,
  alphaMaskWithSpacesAndAccentAndSpecialChars,
} from 'utils/masks';
import i18n from '@i18n';
import {
  cardInitialValues_0,
  cardInitialValues_1,
  cardInitialValues_2,
} from './config';
import useMultiPayment from '../hooks/useMultiPayment';
import useBank from '../hooks/useBank';

const initialValues = {
  cardInitialValues_0,
  cardInitialValues_1,
  cardInitialValues_2,
};

const buttonGuardarBancoStyle = {
  backgroundColor: '#450E99',
  color: 'white',
  padding: '15px 32px',
  textAlign: 'center',
  textDecoration: 'none',
  display: 'inline-block',
  fontSize: '16px',
  margin: '4px 2px',
  marginTop: '10px',
  cursor: 'pointer',
  border: 'none',
  borderRadius: '4px',
};

const CardMethod = ({ name, onSelect, index }) => {
  const { setBankModal, handleSaveNewBank } = useBank();
  const { values, setFieldValue } = useFormikContext();
  const { hasUpdateDifMethods } = useMultiPayment();
  const [otroBanco, setOtroBanco] = useState(false);

  const cardTypes = useSelector((state) =>
    _get(state, 'commons.catalogsBankCardTypes.list', [])
  );

  const valuesName = {
    bank: `bank_${index}`,
    otherBank: `holdbackOtherBankName_${index}`,
  };

  const handleChangeBanco = (e) => {
    setFieldValue(`bank_${index}`, e.target.value);
    if (e.target.value === 'c1f56866-0904-499b-a3fe-1b70a3e207b3') {
      setOtroBanco(true);
      return;
    }
    setOtroBanco(false);
  };

  const handleClickCharge = () => {
    setBankModal({ show: true, cardIndex: index });
  };

  const handleNewBank = () => {
    const passed = handleSaveNewBank(
      values[`holdbackOtherBankName_${index}`],
      valuesName
    );
    setOtroBanco(passed);
  };

  return (
    <>
      <PaymentType
        name={`${name}_${index}`}
        onSelect={onSelect}
        initialValues={initialValues[`cardInitialValues_${index}`]}
        hasUpdateDifMetods={hasUpdateDifMethods}
        title="PAGO CON TARJETA DE CRÉDITO O DÉBITO"
        subtitle="Visa, MasterCard, American Express."
        noContainer
        right={
          <div className="d-flex flex-wrap mt-0">
            <div className="px-2 mr-3">
              <Icons.American width="3.125rem" height="3.125rem" />
            </div>
            <div className="px-2 mr-3">
              <Icons.Mastercard width="3.125rem" height="3.125rem" />
            </div>
            <div className="px-2">
              <Icons.Visa width="3.125rem" height="3.125rem" />
            </div>
          </div>
        }
      >
        <div className="row">
          <div className="col-6 switch-inverted">
            <RadioGroupInput
              id="reservations_payment_paymentType_cardType"
              labelClass="d-flex align-items-center"
              groupClass="col-12 d-flex justify-content-between"
              parentClass="mb-0"
              label=""
              name={`cardType_${index}`}
              options={cardTypes}
            />
          </div>
          <div className="col-6 switch-inverted">
            <Button
              id="reservations_payment_paymentType_cardType_button"
              block
              className="PaymentButton mt-0 mr-1"
              onClick={handleClickCharge}
            >
              Seleccionar tarjeta
            </Button>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <ConnectedSelect
              id="reservations_payment_paymentType_cardType_bank"
              label="Banco*"
              name={`bank_${index}`}
              options="commons.catalogsBanks"
              onChange={handleChangeBanco}
            />
          </div>
          {otroBanco && (
            <>
              <div className="col-md-6 col-sm-12">
                <Input
                  id="reservations_payment_paymentType_cardType_otherBank_input"
                  mask={alphaMaskWithSpacesAndAccent(40)}
                  label="Otro Banco*"
                  placeholder="Ingresa nuevo banco*"
                  name={`holdbackOtherBankName_${index}`}
                  disabled={!otroBanco}
                />
              </div>
              <div className="col-md-6 col-sm-12 d-flex align-items-center">
                <Button
                  style={buttonGuardarBancoStyle}
                  onClick={handleNewBank}
                  id="reservations_payment_paymentType_cardType_button"
                >
                  Guardar Banco
                </Button>
              </div>
            </>
          )}
          <div className="col-6">
            <Input
              id="reservations_payment_paymentType_cardType_cardNumber_input"
              mask={cardNumberValidations}
              maskPlaceholder="#### #### #### ####"
              label="No. de tarjeta*"
              placeholder="No. de tarjeta*"
              name={`cardNumber_${index}`}
            />
          </div>
          <div className="col-6">
            <Input
              id="reservations_payment_paymentType_cardType_reference_input"
              mask={alphaMaskWithSpacesAndAccentAndSpecialChars(50)}
              maskPlaceholder=""
              label="NO. DE REFERENCIA*"
              placeholder="NO. DE REFERENCIA*"
              name={`reference_${index}`}
            />
          </div>
          <div className="col-6">
            <Input
              id="reservations_payment_paymentType_cardType_authorization_input"
              mask={alphaMaskWithSpacesAndAccentAndSpecialChars(50)}
              maskPlaceholder=""
              label="AUTORIZACIÓN*"
              placeholder="AUTORIZACIÓN*"
              name={`authorization_${index}`}
            />
          </div>
          <div className="col-6">
            <DatePickerV2
              id="reservations_payment_paymentType_cardType_paymentDate_input"
              placeholder="Fecha*"
              name="paymentDate"
              label="Fecha*"
              disabled
            />
          </div>
          <div className="col-6">
            <Input
              id="reservations_payment_paymentType_cardType_paymentTime_input"
              mask={hourMask}
              maskPlaceholder=""
              label="Hora*"
              placeholder="Hora*"
              name="paymentTime"
              disabled
            />
          </div>
          <div className="col-6">
            <FileInput
              id="reservations_payment_paymentType_cardType_voucher_input"
              url={values[`voucher_${index}`]}
              displayText={values[`voucher_${index}`]}
              type="file"
              label="Comprobante*"
              placeholder="Comprobante*"
              name={`voucher_${index}`}
              showIcon={false}
            />
          </div>
          <div className="col-6">
            {hasUpdateDifMethods && (
              <NumberInput
                decimals={2}
                max={9_999_999}
                label="Monto en tarjeta"
                placeholder="Monto en tarjeta"
                name={`amountCard_${index}`}
                disabled={!hasUpdateDifMethods}
              />
            )}
          </div>
          <div className="col-md-8 switch-inverted">
            <label htmlFor={`hasHolderName_${index}`}>
              {i18n('RESERVATIONS__PAYMENT__CARD__HOLDER')}
            </label>
            <Switch
              name={`hasHolderName_${index}`}
              id={`hasHolderName_${index}`}
            />
          </div>
          {!values[`hasHolderName_${index}`] && (
            <div className="col-md-6">
              <FileInput
                id="reservations_payment_paymentType_cardType_accountStatus_input"
                url={values[`accountStatus_${index}`]}
                displayText={values[`accountStatus_${index}`]}
                type="file"
                label="Comprobante de Titularidad*"
                placeholder="Comprobante de Titularidad*"
                name={`accountStatus_${index}`}
                showIcon={false}
              />
            </div>
          )}
        </div>
      </PaymentType>
    </>
  );
};

CardMethod.propTypes = {
  name: PropTypes.string,
  setHasUpdatedHoldBack: PropTypes.func,
  hasUpdateDifMethods: PropTypes.bool,
  onSelect: PropTypes.func,
  uuidCustomer: PropTypes.string,
  index: PropTypes.number,
};

CardMethod.defaultProps = {
  name: 'card',
  index: 0,
};

export default CardMethod;
