import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useFormikContext, validateYupSchema } from 'formik';
import {
  Icons,
  NumberInput,
  Button,
  MaskedInput as Input,
  withForm,
} from '@casanova/casanova-common';
import moment from 'moment';
import _get from 'lodash/get';
import PropTypes from 'prop-types';
import i18n from '@i18n';
import { SchedDate } from '@reservations/components';
import { ApplyDiscount } from '@reservations/sections';
import { capitalizeFirstChar, alphaMask } from 'utils/masks';
import { isExtendOfContractFlow } from '@reservations/commons/utils';
import { number2mxn } from 'utils/currency';
import OptionsBar from 'components/OptionsBar';
import { ActionModal } from '@casanova/casanova-common/lib/Modal';
import { IVA } from 'utils/constants/amounts';
import config, { discountSchema, promocodeSchema } from './config';
import './Sumary.scss';
import { VIEW_SEND_PRICE_QUOTE } from 'utils/roles/permissions/reservationsPermissions';
import { RoleVerifier } from 'components';
import { prototype } from 'postcss/lib/previous-map';
import { Button as ButtonPrime } from 'primereact/button';

// TODO: Waiting for backend fix to show discount on summary
const SHOW_DISCOUNT_FLOW = false;

const Summary = ({
  branches = [],
  pickupDateTime,
  pickupBranch,
  returnDateTime,
  returnBranch,
  fullVehicle,
  vehicleLinePrice,
  selectedCategoryName,
  discountOptions,
  discountForm,
  applyBranchDiscount,
  cancelBranchDiscount,
  validatePromocode,
  openSendQuoteDialog,
  editPlan,
  editDiscount,
  cancelEditDiscount,
  onUpdateForm,
  isReserving,
  customerName,
  title = '',
  titleHeader = false,
  showPlan = true,
  children,
  extendContract,
  // customerMail,
  obtenerPrecio,
  savePayment,
  reservationId,
}) => {
  const {
    values,
    setFieldValue,
    setFieldTouched,
    setErrors,
    errors,
  } = useFormikContext();

  const promocodeOptions = [
    { value: false, label: 'No' },
    { value: true, label: 'Sí' },
  ];

  const [selectedStartBranch, setSelectedStartBranch] = useState({});
  const [showContinueWithoutPayment, setShowContinueWithoutPayment] = useState(
    false
  );
  const [request, setRequest] = useState({});
  const [selectedEndBranch, setSelectedEndBranch] = useState({});
  const [symbol, setSymbol] = useState('%');
  const [discountAmount, setDiscountAmount] = useState(0);
  const [openConfirmationApply, setOpenConfirmationApply] = useState(false);

  const currentPlan = fullVehicle.vehicleLinePrices.filter(
    (plan) => plan.uuid === vehicleLinePrice
  );
  const { name, price, period } = currentPlan[0];
  const amount = price / period;
  const ivaOfAmount = (amount * IVA) / (1 + IVA);
  const {
    vehicleLineName,
    vehicleBrandName,
    vehicleTypeName,
    vehicleLineImagesOriginalUrls,
  } = fullVehicle;
  const isExtendContract = useMemo(() => isExtendOfContractFlow(), [
    isExtendOfContractFlow(),
  ]);
  const startDate = moment(pickupDateTime, 'yyyy-MM-DD hh:mm');
  const endDate = moment(returnDateTime, 'yyyy-MM-DD hh:mm');

  const differenceD = endDate.diff(startDate, 'days');
  const differenceM = endDate.diff(startDate, 'minutes');

  const minutesToDays = differenceM / (24 * 60);
  const hasRemaining = minutesToDays - differenceD > 0;

  const daysDifference = hasRemaining ? differenceD + 1 : differenceD;
  const total = amount * daysDifference;
  const daysAdded = extendContract.daysAdded;
  const extendDiscount = isExtendContract ? daysAdded * amount - total : 0;

  const returnDateTimeName = isExtendContract
    ? 'Nueva fecha de devolución'
    : 'Fecha de devolución';

  useEffect(() => {
    if (branches && branches.length > 0 && pickupBranch && returnBranch) {
      setSelectedStartBranch(
        branches
          .filter((item) => item.uuid === pickupBranch)
          .reduce((_, current) => current) || {}
      );

      setSelectedEndBranch(
        branches
          .filter((item) => item.uuid === returnBranch)
          .reduce((_, current) => current) || {}
      );
    }
  }, [branches, pickupBranch, returnBranch]);

  useEffect(() => {
    if (isReserving) {
      obtenerPrecio(total);
    }
  }, [total]);

  const vehicleTitle = `${capitalizeFirstChar(
    vehicleLineName.toLowerCase()
  )} ${capitalizeFirstChar(vehicleBrandName.toLowerCase())}`;

  const handleWithoutPayment = () => {
    setRequest({
      paymentForm: 'CASH',
      amount: 0,
      discount: 0,
      paymentType: 'CONTRACT',
      reservation: reservationId,
      withoutPayment: true,
    });
    setShowContinueWithoutPayment(true);
  };

  const handleOnContinueWithoutPayment = () => {
    setShowContinueWithoutPayment(false);
    savePayment({ request });
  };

  const handleOnCancelWithoutPayment = () => {
    setShowContinueWithoutPayment(false);
    setRequest({});
  };

  const handleChangeDiscountType = useCallback(
    (option) => {
      const symbolFiltered = discountOptions.filter(
        (discount) => discount.value === option.value
      );
      const sym =
        symbolFiltered.length > 0 ? symbolFiltered[0].symbol : undefined;
      setSymbol(sym);
    },
    [discountOptions]
  );

  const handleOnApplyDiscount = useCallback(() => {
    setFieldValue('discountTypeSymbol', symbol);
    setFieldTouched('discount', true);
    validateYupSchema(
      { ...values, discountTypeSymbol: symbol, total },
      discountSchema
    )
      .then(() => {
        setErrors({});
        let discountAmountInner = values.discount;
        if (symbol === '%') {
          discountAmountInner = total * (values.discount / 100);
        }
        applyBranchDiscount({
          discount: values.discount,
          discountAmount: discountAmountInner,
          discountType: values.discountType,
          total,
        });
        setDiscountAmount(discountAmountInner);
      })
      .catch((errorsdd) => {
        setErrors({ fullDiscount: errorsdd.errors[0] });
      });
  }, [
    values,
    applyBranchDiscount,
    setFieldValue,
    symbol,
    total,
    setFieldTouched,
    setErrors,
  ]);

  const handleOnApplyPromocode = useCallback(() => {
    setFieldTouched('promocode', true);
    validateYupSchema(values, promocodeSchema)
      .then(() => {
        setErrors({});
        validatePromocode({
          promoCode: values.promocode,
        });
      })
      .catch((errorsdd) => {
        setErrors({ promocode: errorsdd.errors[0] });
      });
  }, [values, setFieldTouched, setErrors, validatePromocode]);

  useEffect(() => {
    if (!discountForm) return;
    if (discountForm.isPromocode) {
      if (discountForm.finished && !discountForm.success) {
        setErrors({ promocodeResponse: discountForm.message });
        setDiscountAmount(0);
      } else {
        setDiscountAmount(discountForm.promocodeDiscount);
        setErrors({});
      }
      return;
    }

    if (isReserving && discountForm.finished && discountForm.success) {
      setDiscountAmount(discountForm.discountAmount);
      setErrors({});
    }
  }, [discountForm, setErrors]);

  const discountWord = discountForm.isPromocode
    ? values.promocode
    : 'DESCUENTO';

  const handleSendQuote = () => {
    openSendQuoteDialog();
  };

  const handleOnCloseApply = useCallback(() => {
    const prevDiscount = _get(discountForm, 'prevBranchDiscount', '');
    if (values.discount !== '' && prevDiscount !== '') {
      setFieldValue('discount', prevDiscount);
      cancelEditDiscount();
    } else {
      setDiscountAmount(0);
    }
    cancelBranchDiscount();
  }, [
    values.discount,
    cancelEditDiscount,
    cancelBranchDiscount,
    discountForm,
    setFieldValue,
  ]);

  const getDiscountMessageModal = useCallback(() => {
    if (!discountForm.isPromocode) {
      return 'Sólo puede aplicar un descuento, si agregas un código promocional, se perderá el descuento autorizado. ¿Estás seguro que quieres continuar?';
    }
    return 'Sólo puede aplicar un descuento, si agregas un descuento de sucursal, se perderá el código promocional. ¿Estás seguro que quieres continuar?';
  }, [discountForm.isPromocode]);

  const beforeApplyDiscount = useCallback(() => {
    if (
      discountForm.finished &&
      discountForm.success &&
      discountForm.isPromocode
    ) {
      setOpenConfirmationApply(true);
    } else {
      handleOnApplyDiscount();
    }
  }, [
    setOpenConfirmationApply,
    discountForm.finished,
    discountForm.success,
    handleOnApplyDiscount,
    discountForm.isPromocode,
  ]);

  const beforeApplyPromocode = useCallback(() => {
    if (discountForm.finished && discountForm.success) {
      setOpenConfirmationApply(true);
    } else {
      handleOnApplyPromocode();
    }
  }, [
    setOpenConfirmationApply,
    discountForm.finished,
    discountForm.success,
    handleOnApplyPromocode,
  ]);

  const handleOnCancelAction = useCallback(() => {
    if (discountForm.isPromocode) {
      onUpdateForm({ ...values, discount: '', discountType: [] }, false);
    } else {
      setFieldValue('withPromocode', []);
      setFieldValue('promocode', '');
    }
    setOpenConfirmationApply(false);
  }, [setFieldValue, discountForm.isPromocode, onUpdateForm, values]);

  const handleOnContinueAction = useCallback(() => {
    if (!discountForm.isPromocode) {
      handleOnApplyPromocode();
      onUpdateForm({ ...values, discount: '', discountType: [] }, false);
    } else {
      handleOnApplyDiscount();
    }
    setOpenConfirmationApply(false);
  }, [
    discountForm.isPromocode,
    handleOnApplyDiscount,
    handleOnApplyPromocode,
    onUpdateForm,
    values,
  ]);

  const handleChangePromoOptions = useCallback(
    (option) => {
      if (!option.value) {
        setFieldValue('promocode', '');
      }
    },
    [setFieldValue]
  );

  const getTitleHeader = useCallback(() => {
    if (titleHeader && title) return title;
    return isReserving ? customerName : customerName;
  }, [titleHeader, title, isReserving, customerName]);

  return (
    <>
      <ActionModal
        open={openConfirmationApply}
        icon={<Icons.Quote width="6rem" height="6rem" />}
        onClose={handleOnCancelAction}
        onAction={handleOnContinueAction}
        title="¡Importante!"
        actionLabel="Sí"
        closeLabel="No"
        message={getDiscountMessageModal()}
      />
      <ActionModal
        open={showContinueWithoutPayment}
        icon={<Icons.Quote width="6rem" height="6rem" />}
        onClose={handleOnCancelWithoutPayment}
        onAction={handleOnContinueWithoutPayment}
        title="¿Continuar?"
        message="Continuar con la reservación sin generar pago"
        actionLabel="Sí"
        closeLabel="No"
      />
      <ApplyDiscount
        open={discountForm.openForm}
        onClose={handleOnCloseApply}
      />
      <div className="col-4 col-xl-3">
        <div className="main-content Summary">
          <div className="header">{getTitleHeader()}</div>
          <div className="vehicle-details">
            <p>
              <span className="common-info-text pr-5">
                {selectedCategoryName} / {vehicleTypeName}
              </span>
              <br />
              <span className="title">{vehicleTitle}</span>
            </p>
            <div className="vehicle-image">
              <img src={vehicleLineImagesOriginalUrls} alt="" />
            </div>
            {!isReserving && <span onClick={editPlan}>Editar</span>}
          </div>
          <div className="schedule-dates">
            {children || (
              <>
                <SchedDate
                  date={pickupDateTime}
                  branch={selectedStartBranch.name}
                  title="Fecha de salida"
                />
                <SchedDate
                  date={returnDateTime}
                  branch={selectedEndBranch.name}
                  title={returnDateTimeName}
                  end
                />
              </>
            )}
          </div>
          {showPlan && (
            <p className="plan">
              <span>PLAN: </span> {name}
            </p>
          )}
          {!isReserving && (
            <div className="row promocode">
              <div className="col-8 question">
                ¿Existe algún código promocional?
              </div>
              <div className="col-4 px-0">
                <OptionsBar
                  small
                  name="withPromocode"
                  options={promocodeOptions}
                  onChangeSelection={handleChangePromoOptions}
                />
              </div>
              {values.withPromocode.includes(true) && (
                <div className="col-12 pr-4">
                  <Input
                    name="promocode"
                    label="CÓDIGO PROMOCIONAL"
                    placeholder="CÓDIGO PROMOCIONAL"
                    mask={alphaMask(15)}
                    maskPlaceholder=""
                    disabled={
                      discountForm.isPromocode &&
                      discountForm.finished &&
                      discountForm.success
                    }
                  />
                  {errors && errors.promocodeResponse && (
                    <div className="col-form-error text-danger">
                      {errors.promocodeResponse}
                    </div>
                  )}
                  {(!discountForm.isPromocode ||
                    !discountForm.finished ||
                    !discountForm.success) && (
                    <Button block outline onClick={beforeApplyPromocode}>
                      {' '}
                      Aplicar{' '}
                    </Button>
                  )}
                </div>
              )}
            </div>
          )}

          {SHOW_DISCOUNT_FLOW && !isReserving && (
            <div className="discount">
              <label className="composed-input-label" htmlFor="discount">
                Descuento
              </label>
              <div className="discount-input">
                <div className="options">
                  <OptionsBar
                    onChangeSelection={handleChangeDiscountType}
                    small
                    name="discountType"
                    options={discountOptions}
                    disabled={!discountForm.allowEditDiscount}
                  />
                </div>
                <div className="number">
                  <NumberInput
                    disabled={!discountForm.allowEditDiscount}
                    maskPrefix=""
                    name="discount"
                    max={100_000}
                  />
                </div>
              </div>
              {errors && errors.fullDiscount && (
                <div className="col-form-error text-danger">
                  {errors.fullDiscount}
                </div>
              )}
              {values.discountType.length > 0 &&
                discountForm.allowEditDiscount && (
                  <Button block outline onClick={beforeApplyDiscount}>
                    Aplicar
                  </Button>
                )}
              {!discountForm.allowEditDiscount && (
                <span className="discount-edit" onClick={editDiscount}>
                  Editar
                </span>
              )}
            </div>
          )}

          <div className="totals px-3 pb-5">
            {isReserving && (
              <div className="common-info-text text-right text-uppercase">
                Renta: {number2mxn(amount - ivaOfAmount)} <br />
                {discountForm.finished &&
                  discountForm.success &&
                  discountAmount > 0 && (
                    <span>
                      {discountWord}: {number2mxn(discountAmount)}
                    </span>
                  )}
                <br />
                IVA: {number2mxn(ivaOfAmount)}
              </div>
            )}
            <br />
            {isExtendContract && (
              <div>A cuenta: {number2mxn(extendDiscount)}</div>
            )}
            <div className="common-info-text font-weight-bold text-uppercase d-flex justify-content-between">
              <div>{i18n('RESERVATIONS__SUMMARY__TOTAL_AMOUNT')}</div>
              {discountForm.finished &&
                discountForm.success &&
                discountAmount > 0 && (
                  <div className="text-right text-decoration-line-through ">
                    {number2mxn(total)}
                  </div>
                )}
            </div>
            <br />
            <div className="common-amount-text text-uppercase text-right">
              mxn{' '}
              {number2mxn(
                isExtendContract
                  ? total + extendDiscount
                  : total - discountAmount
              )}
            </div>
            <div className="common-info-text font-weight-bold text-uppercase text-right">
              POR {isExtendContract ? daysAdded : daysDifference} DÍAS
            </div>
            <br />
            {!isReserving && (
              <RoleVerifier identifier={VIEW_SEND_PRICE_QUOTE}>
                <Button
                  block
                  onClick={handleSendQuote}
                  id="quoter_send_quote_button"
                >
                  Enviar cotización
                </Button>
              </RoleVerifier>
            )}
          </div>
          {isReserving && (
            <div className="footer">
              <ButtonPrime
                label="Continuar sin pago"
                text
                onClick={handleWithoutPayment}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

Summary.propTypes = {
  isReserving: PropTypes.bool,
};

Summary.defaultProps = {
  isReserving: false,
};

export default withForm({ config })(Summary);
