import classNames from 'classnames';
import { CellText, Cell } from 'components/ColumnsCells';
import i18n from '@i18n';

export default [
  {
    label: i18n('PAYMENTS_AND_CHARGES__CONTRACT_DETAIL__LIST__MOVEMENT_TYPE'),
    field: 'movementType',
    align: 'center',
    renderer: CellText,
  },
  {
    label: 'Folios',
    field: 'folioPayment',
    align: 'center',
    renderer: (props) => {
      const { data = {} } = props;
      let folioPayment = 'NO ASIGNADO';
      if (data.foliosData && data.foliosData.length > 0) {
        const allFolios = data.foliosData.map((folio) => folio.folioPayment);
        const cashFolios = allFolios.filter(
          (payment) => payment === 'Pago en efectivo'
        );

        if (cashFolios.length > 0) {
          folioPayment = cashFolios.join(', ');
        } else {
          folioPayment = allFolios.join(', ');
        }
      }
      return (
        <Cell
          {...props}
          value={folioPayment}
          align="left"
          textClass="text-left"
        />
      );
    },
  },
  {
    label: 'Forma de pago',
    field: 'paymentForm',
    align: 'center',
    renderer: (props) => {
      const { data = {} } = props;
      let paymentForms = 'NO ASIGNADO';

      if (data.foliosData && data.foliosData.length > 0) {
        const allPayments = data.foliosData.map((folio) => folio.paymentForm);
        const cashPayments = allPayments.filter(
          (payment) => payment === 'Pago en efectivo'
        );

        if (cashPayments.length > 0) {
          paymentForms = cashPayments.join(', ');
        } else {
          paymentForms = allPayments.join(', ');
        }
      }
      return (
        <Cell
          {...props}
          value={paymentForms}
          align="left"
          textClass="text-left"
        />
      );
    },
  },
  {
    label: i18n('PAYMENTS_AND_CHARGES__CONTRACT_DETAIL__LIST__CONCEPT'),
    field: 'concept',
    align: 'center',
    renderer: (props) => {
      const { value } = props;
      console.log('value', value);
      return (
        <Cell
          {...props}
          value={value}
          align="center"
          textClass="text-center"
          style={{ minWidth: '325px', textAlign: 'center' }}
        />
      );
    },
  },
  {
    label: i18n('PAYMENTS_AND_CHARGES__CONTRACT_DETAIL__LIST__CHARGES'),
    field: 'charges',
    align: 'center',
    renderer: (props) => {
      const { value } = props;
      return (
        <Cell
          className={classNames(
            'text-center',
            value && value !== '-' && 'text-danger'
          )}
        >
          {() => value}
        </Cell>
      );
    },
  },
  {
    label: i18n('PAYMENTS_AND_CHARGES__CONTRACT_DETAIL__LIST__PAYMENTS'),
    field: 'payments',
    align: 'center',
    renderer: (props) => {
      const { value } = props;
      return (
        <Cell
          className={classNames(
            'text-center',
            value && value !== '-' && 'text-success'
          )}
        >
          {() => value}
        </Cell>
      );
    },
  },
  {
    label: i18n('PAYMENTS_AND_CHARGES__CONTRACT_DETAIL__LIST__RECEIPT'),
    field: 'receipt',
    align: 'center',
    renderer: CellText,
  },
  {
    label: i18n('PAYMENTS_AND_CHARGES__CONTRACT_DETAIL__LIST__DATE'),
    field: 'applicationDate',
    align: 'center',
    renderer: CellText,
  },
];

export const contextualMenu = {
  CANCEL: {
    label: i18n('COMMONS__CANCEL__TEXT'),
    name: 'cancel',
    disabled: false,
    options: [],
    showExpanded: false,
  },
};
