import React from 'react';
import { AdditionalHeaderCamp } from 'components';
import { CellText } from 'components/ColumnsCells';
import {
  onlyLettersAndNumbers,
  alphaMaskWithSpaces,
  numericMask,
} from 'utils/masks';
import {
  VEHICLE_LIST_FIELDS as FIELDS,
  VEHICLES_STATUS,
} from '@vehicles/common/constants';
import {
  VIEW_VEHICLE_GENERAL_INFORMATION,
  VIEW_VEHICLE_GENERAL_INFORMATION_HISTORY,
  CREATE_VEHICLE_SINISTER,
  VIEW_VEHICLE_RECORDS,
  VIEW_VEHICLE_CATALOG_RD,
} from 'utils/roles/permissions';
import { validateRolePermissions } from 'utils/roles';
import { Button, SearchInput } from '@casanova/casanova-common';

export default [
  {
    label: 'Marca',
    field: 'brand',
    renderer: CellText,
    align: 'center',
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp
        mask={alphaMaskWithSpaces(20)}
        name={FIELDS.BRAND}
        searchKey={FIELDS.BRAND}
      />
    ),
  },
  {
    label: 'Línea',
    field: 'line',
    renderer: CellText,
    align: 'center',
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp
        mask={alphaMaskWithSpaces(20)}
        name={FIELDS.LINE}
        searchKey={FIELDS.LINE}
      />
    ),
  },
  {
    label: 'Versión',
    field: 'version',
    renderer: CellText,
    align: 'center',
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp
        mask={alphaMaskWithSpaces(20)}
        name={FIELDS.VERSION}
        searchKey={FIELDS.VERSION}
      />
    ),
  },
  {
    label: 'Modelo',
    field: 'model',
    renderer: CellText,
    align: 'center',
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp
        mask={numericMask(20)}
        name={FIELDS.MODEL}
        searchKey={FIELDS.MODEL}
      />
    ),
  },
  {
    label: 'No. de serie',
    field: 'serialNumber',
    renderer: CellText,
    align: 'center',
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp
        mask={onlyLettersAndNumbers(17)}
        name={FIELDS.SERIAL_NUMBER}
        searchKey={FIELDS.SERIAL_NUMBER}
      />
    ),
  },
  {
    label: 'No. placa',
    field: 'plateNumber',
    renderer: CellText,
    align: 'center',
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp
        mask={onlyLettersAndNumbers(17)}
        name={FIELDS.PLATE}
        searchKey={FIELDS.PLATE}
      />
    ),
  },
  {
    label: 'Almacen',
    field: 'warehouse',
    renderer: CellText,
    align: 'center',
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp
        mask={alphaMaskWithSpaces(20)}
        name="warehouse"
        searchKey="warehouse"
      />
    ),
  },
  {
    label: 'Contrato',
    field: 'contractNumber',
    renderer: CellText,
    align: 'center',
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp
        mask={alphaMaskWithSpaces(20)}
        name={FIELDS.CONTRACT_NUMBER}
        searchKey={FIELDS.CONTRACT_NUMBER}
        disabled={true}
      />
    ),
  },
  // {
  //   label: 'Proveedor',
  //   field: 'provider',
  //   renderer: CellText,
  //   align: 'center',
  //   AdditionalHeaderCamp: () => (
  //     <AdditionalHeaderCamp
  //       mask={alphaMaskWithSpaces(20)}
  //       name={FIELDS.PROVIDER}
  //       searchKey={FIELDS.PROVIDER}
  //     />
  //   ),
  // },
  {
    label: 'Unidad de negocio',
    field: 'businessArea',
    renderer: CellText,
    align: 'center',
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp
        name="businessArea"
        searchKey="businessArea"
        options="commons.catalogsBusinessAreas"
        selectProps={{ availableFirstValue: true }}
        type="SELECT"
        // staticValue={
        //   validateRolePermissions(VIEW_VEHICLE_CATALOG_RD) &&
        //   BUSINESS_AREA_UUIDS.RENTA_DIARIA
        // }
        disabled={validateRolePermissions(VIEW_VEHICLE_CATALOG_RD)}
      />
    ),
  },
  {
    label: 'Sucursal',
    field: 'branch',
    renderer: CellText,
    align: 'center',
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp
        mask={alphaMaskWithSpaces(20)}
        name="branch"
        searchKey="branch"
        options="commons.branches"
        selectProps={{ availableFirstValue: true }}
        type="SELECT"
      />
    ),
  },
  {
    label: 'Categoría',
    field: 'categories',
    renderer: CellText,
    align: 'center',
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp
        mask={alphaMaskWithSpaces(20)}
        name="categories"
        searchKey="categories"
      />
    ),
  },
  {
    label: 'Propietario',
    field: 'owner',
    renderer: CellText,
    align: 'center',
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp
        mask={alphaMaskWithSpaces(20)}
        name={FIELDS.OWNER}
        searchKey={FIELDS.OWNER}
      />
    ),
  },
  {
    label: 'Estatus',
    field: 'status',
    renderer: CellText,
    align: 'center',
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp
        name={FIELDS.STATUS}
        searchKey={FIELDS.STATUS}
        options="vehicles.filters.vehiclesList.statuses"
        selectProps={{ fullKey: true, availableFirstValue: true }}
        type="SELECT"
        disabled={validateRolePermissions(VIEW_VEHICLE_CATALOG_RD)}
        // staticValue={
        //   validateRolePermissions(VIEW_VEHICLE_CATALOG_RD) &&
        //   VEHICLE_STATUS_UUIDS.DISPONIBLE
        // }
      />
    ),
  },
  {
    label: 'Subestatus',
    field: 'subStatus',
    renderer: CellText,
    align: 'center',
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp
        name="subStatus"
        searchKey="subStatus"
        options="vehicles.filters.vehiclesList.substatuses"
        selectProps={{ fullKey: true, availableFirstValue: true }}
        type="SELECT"
      />
    ),
  },
];

export const getContextualMenu = () => {
  const VEHICLE_DETAILS = {
    label: 'Ver detalle',
    name: 'vehicleDetail',
    disabled: false,
    options: [],
    showExpanded: false,
  };

  const VEHICLE_INFO = {
    label: 'Expediente',
    name: 'vehicleInfo',
    disabled: false,
    options: [],
    showExpanded: false,
  };

  const VEHICLE_HISTORIC_DETAIL = {
    label: 'Ver detalle historico del vehículo',
    name: 'vehicleHistoricDetails',
    disabled: false,
    options: [],
    showExpanded: false,
  };


  return {
    VEHICLE_DETAILS,
    VEHICLE_INFO,
    VEHICLE_HISTORIC_DETAIL,
  };
};

export const contextualMenu = getContextualMenu();

export const getVehicleContextualMenu = (vehicleStatus = '') => {
  let menu = getContextualMenu();
  if (!validateRolePermissions(VIEW_VEHICLE_GENERAL_INFORMATION)) {
    delete menu.VEHICLE_DETAILS;
    delete menu.VEHICLE_INFO;
  }
  if (!validateRolePermissions(VIEW_VEHICLE_GENERAL_INFORMATION_HISTORY))
    delete menu.VEHICLE_HISTORIC_DETAIL;
  if (!validateRolePermissions(VIEW_VEHICLE_RECORDS)) delete menu.VEHICLE_INFO;
  if (
    vehicleStatus.toUpperCase() == VEHICLES_STATUS.SOLD ||
    !validateRolePermissions(CREATE_VEHICLE_SINISTER)
  )
    delete menu.REGISTER_OF_SINISTER;
  return menu;
};

// Flow temporarily disabled
// MARK_FOR_SALE: {
//   label: 'Marcar para venta',
//   name: 'markForSale',
//   disabled: false,
//   options: [],
//   showExpanded: false,
// },

export const MASSIVE_LOAD_OPTIONS = {
  VEHICLE: {
    label: 'Vehículo',
    name: 'VEHICLE',
    showExpanded: false,
  },
  VEHICLES_FOR_SALES: {
    label: 'Venta',
    name: 'SALE',
    showExpanded: false,
  },
};
