import React from 'react';
import PropTypes from 'prop-types';
import { Button, TableNavigation } from '@casanova/casanova-common';
import { RoleVerifier } from 'components';

export default function TableBottom({
  search,
  onChange,
  onClick,
  textButton = '',
  showButton = true,
  actionPermission = null,
}) {
  return (
    <div className="row">
      <div className="col-12 col-xl-7 table-paginator text-right d-flex justify-content-between align-items-center">
        <TableNavigation search={search} onChange={onChange} />
      </div>
      {showButton && (
        <div className="col-12">
          <div className="row">
            <div className="col" />
            <div className="offset-6 col-3">
              {actionPermission ? (
                <RoleVerifier identifier={actionPermission}>
                  <Button block onClick={onClick} id="table_bottom_button">
                    {textButton}
                  </Button>
                </RoleVerifier>
              ) : (
                <Button block onClick={onClick} id="table_bottom_button">
                  {textButton}
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

TableBottom.propTypes = {
  search: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  textButton: PropTypes.string,
  showButton: PropTypes.bool,
  actionPermission: PropTypes.string,
};
