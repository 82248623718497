import { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import _get from 'lodash/get';
import { useSelector } from 'react-redux';
import {
  Section,
  MaskedInput as Input,
  Button,
  Table,
  CardRow,
  RadioGroupInput,
} from '@casanova/casanova-common';
import { onlyLettersAndNumbers } from '@casanova/casanova-common/lib/utils/masks';
import { transformIdOfList } from '@casanova/casanova-common/lib/utils/transformHelpers';
import i18n from '@i18n';
import { FORM_ACTIONS } from 'utils/constants/formActions';
import columns, { VEHICLE_CARD_ROW_FIELDS } from './columns';
import './VehicleAssignment.scss';

export default function VehicleAssignment({
  action,
  getCatalogueOfVehicles,
  vehiclesSearchList = [],
  categories,
  setRentalPlanOptions,
}) {
  const [selectedVehicles, setSelectedVehicles] = useState([]);
  const [updated, setUpdated] = useState(false);

  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext();

  const {
    searchText = '',
    vehicles = [],
    listOfVehicles = [],
    hasSearched = false,
    vehicleCategory = '',
  } = values;

  const handleRemoveVehicle = useCallback((vehicle) => {
    setSelectedVehicles((prevSelectedVehicles) =>
      prevSelectedVehicles.filter(({ uuid = '' }) => uuid !== vehicle.uuid)
    );
    setFieldTouched('vehicles', true);
    setRentalPlanOptions([]);
  }, []);

  const rentalPlans = useSelector((state) =>
    _get(state, 'commons.catalogsRentalPlans.list', [])
  );

  const filterRentalPlan = (vehicle) => {
    const vRentalPlans = vehicle.rentalPlan.split(',');
    const filter = rentalPlans.filter((rp) => {
      return (
        vRentalPlans.find((vrp) => {
          return vrp == rp.name;
        }) != undefined
      );
    });
    setRentalPlanOptions(
      filter.map((rp) => {
        return {
          ...rp,
          temporalStartDate: vehicle.temporalStartDate,
          temporalEndDate: vehicle.temporalEndDate,
        };
      })
    );
  };

  const resetQuoteForm = () => {
    setFieldValue('dateEnd', '');
    setFieldValue('startHour', '');
    setFieldValue('endHour', '');
    setFieldValue('daysNumber', '');
    setFieldValue('planType', '');
  };

  const handleAddVehicle = (vehicle) => () => {
    resetQuoteForm();
    filterRentalPlan(vehicle);
    setSelectedVehicles([vehicle]);
    setFieldValue('hasAddVehicle', true);
  };

  const handleVehicleSearch = useCallback(
    (evt) => {
      evt?.preventDefault();

      if (searchText.length > 2) {
        getCatalogueOfVehicles({
          params: { text: searchText, vehicleCategory },
          onlyOnFloor: true,
        });
        setFieldValue('hasSearched', true);
        setFieldValue('hasAddVehicle', false);
      }
    },
    [getCatalogueOfVehicles, searchText, vehicleCategory, setFieldValue]
  );

  useEffect(() => {
    const filteredVehicles = vehiclesSearchList.filter((vehicle) => {
      const notSelectedVehicle = !selectedVehicles.some(
        (selectedVehicle) => selectedVehicle.uuid === vehicle.uuid
      );
      return notSelectedVehicle;
    });

    setFieldValue('listOfVehicles', filteredVehicles);
  }, [vehiclesSearchList, selectedVehicles, setFieldValue]);

  useEffect(() => {
    setFieldValue('vehicles', transformIdOfList(selectedVehicles, 'uuid'));
  }, [setFieldValue, selectedVehicles]);

  useEffect(() => {
    if (selectedVehicles.length === 0 && vehicles.length > 0 && !updated) {
      setSelectedVehicles(vehicles);
      setUpdated(true);
    }
  }, [selectedVehicles, vehicles, updated]);

  useEffect(() => {
    if (hasSearched && searchText.length <= 1) {
      setFieldValue('hasSearched', false);
    }
  }, [searchText, hasSearched, setFieldValue]);

  const handleChangeCategory = useCallback(
    (category) => {
      setFieldValue('vehicleCategory', category.value);
    },
    [setFieldValue]
  );

  useEffect(() => {
    if (categories.length > 0 && !vehicleCategory) {
      const defaultCategory = categories.find((cat) => cat.isDefault);
      if (defaultCategory)
        setFieldValue('vehicleCategory', defaultCategory.value);
    }
  }, [vehicleCategory, categories]);

  return (
    <Section
      title={i18n('RESERVATIONS__NOT_PRICE_QUOTE__VEHICLE_ASSIGNMENT')}
      uppercase
      className="VehicleAssignment mt-3"
    >
      <form onSubmit={handleVehicleSearch}>
        <div className="row mb-2">
          <div className="col-12 col-md-4 col-xl-3">
            <RadioGroupInput
              id="dailyRentContracts_contractQuote"
              labelClass="d-flex align-items-center"
              groupClass="col-12 d-flex justify-content-between"
              parentClass="mb-0"
              label=""
              name="vehicleCategory"
              options={categories}
              onChange={handleChangeCategory}
            />
          </div>
          <div className="col" />
        </div>
        <div className="row">
          <div className="col-md-5 col-xl-4">
            <Input
              id="dailyRentContracts_contractQuote_searchVehicle_input"
              mask={onlyLettersAndNumbers(22)}
              maskPlaceholder=""
              label={i18n('RESERVATIONS__NOT_PRICE_QUOTE__SEARCH_VEHICLE')}
              placeholder={i18n(
                'RESERVATIONS__NOT_PRICE_QUOTE__SEARCH_VEHICLE'
              )}
              name="searchText"
              upperCase
              infoText={i18n(
                'RESERVATIONS__NOT_PRICE_QUOTE__SEARCH_VEHICLE__TEXT_INFO'
              )}
            />
          </div>
          <div className="col-md-4 col-xl-2 d-flex align-items-start justify-content-start pt-2">
            <Button
              className="px-5 mt-4"
              onClick={handleVehicleSearch}
              id="dailyRentContracts_contractQuote_searchVehicle_button"
            >
              {i18n('COMMONS__SEARCH')}
            </Button>
          </div>
        </div>
      </form>

      {action !== FORM_ACTIONS.VIEW && (
        <div className="row">
          <div className="col-12 SearchList">
            {transformIdOfList(listOfVehicles, 'uuid').map((vehicle, index) => {
              const items = Object.entries(vehicle)
                .filter(([key]) => VEHICLE_CARD_ROW_FIELDS[key])
                .map(([key, value]) => ({
                  value,
                  bold: VEHICLE_CARD_ROW_FIELDS[key].bold,
                }));

              const itemsWithId = transformIdOfList(items);

              const hasChecked = selectedVehicles.some(
                ({ uuid }) => uuid === vehicle.uuid
              );

              return (
                <CardRow
                  key={index}
                  items={itemsWithId}
                  withAdd
                  onAdd={handleAddVehicle(vehicle)}
                  check={hasChecked}
                  withExternalCheck
                  onChangeCheck={handleAddVehicle(vehicle)}
                  className="VehicleAssignment__Card"
                  textRight=""
                />
              );
            })}
          </div>
          <div className="col-12 d-flex justify-content-end">
            {errors.vehicles && touched.vehicles && (
              <span className="text-danger col-form-error">
                {errors.vehicles}
              </span>
            )}
          </div>
        </div>
      )}

      <div className="row mt-3">
        <Table
          dataList={selectedVehicles}
          columns={columns}
          removeItems={selectedVehicles.length > 0}
          onRemoveItem={handleRemoveVehicle}
          emptyMessage={i18n('RESERVATIONS__NOT_PRICE_QUOTE__VEHICLE_ADDED')}
        />
      </div>
    </Section>
  );
}

VehicleAssignment.propTypes = {
  action: PropTypes.string,
  getCatalogueOfVehicles: PropTypes.func,
  vehiclesSearchList: PropTypes.arrayOf(PropTypes.object),
};
