"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AUTH = void 0;
var AUTH = exports.AUTH = {
  TOKEN: 'tkn',
  PERMISSIONS: 'role_permissions',
  ROLE_PATHS: 'role_paths',
  ROLE_MODULES: 'role_modules'
};