import i18n from '@i18n';
import 'moment/locale/es';
import * as yup from 'yup';
import _isEmpty from 'lodash/isEmpty';
import { REQUIRED_MESSAGE } from 'utils/constants';
import { validityCardValidation } from 'utils/validations/cards';

const holdbackSchema = {
  holdbackRetention: yup.mixed().when('needHoldback', {
    is: (needHoldback) => needHoldback,
    then: yup.string().required(REQUIRED_MESSAGE),
    otherwise: yup.string(),
  }),
  holdbackOtherRetention: yup
    .string()
    .when(['needHoldback', 'holdbackRetention'], {
      is: (needHoldback, holdbackRetention) =>
        needHoldback && holdbackRetention === 'otro',
      then: yup.string().required(REQUIRED_MESSAGE),
      otherwise: yup.string(),
    }),
  holdbackCardHolder: yup.string().when('needHoldback', {
    is: (needHoldback) => needHoldback,
    then: yup
      .string()
      .required(REQUIRED_MESSAGE)
      .min(3, i18n('ERROR__COMMONS__MIN_LENGTH', [3])),
    otherwise: yup.string(),
  }),
  holdbackBank: yup.string().when('needHoldback', {
    is: (needHoldback) => needHoldback,
    then: yup.string().required(REQUIRED_MESSAGE),
    otherwise: yup.string(),
  }),
  holdbackOtherBankName: yup.string(),
  holdbackCard: yup.string().when('needHoldback', {
    is: (needHoldback) => needHoldback,
    then: yup
      .string()
      .required(REQUIRED_MESSAGE)
      .matches(/[0-9\s]{19,20}$/, 'El número de tarjeta debe tener 16 dígitos y solo contener números.')
      .max(20, i18n('ERROR__COMMONS__MAX_LENGTH', [16])),
    otherwise: yup.string(),
  }),
  holdbackValidity: yup.string().when('needHoldback', {
    is: (needHoldback) => needHoldback,
    then: validityCardValidation.required(REQUIRED_MESSAGE),
    otherwise: yup.string(),
  }),
  holdbackCode: yup.string(),
};

export default holdbackSchema;
