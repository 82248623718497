import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { PaymentType } from '@reservations/components';
import { NumberInput } from '@casanova/casanova-common';
import { cashInitialValues } from './config';
import useMultiPayment from '../hooks/useMultiPayment';
import CashBill from '../../commons/CashBill';
import { useFormikContext } from 'formik';

const CashMethod = ({ name }) => {
  const {
    handleMethodChange,
    hasUpdateDifMethods,
    billDetails,
  } = useMultiPayment();
  const { values } = useFormikContext();

  const totalCash = hasUpdateDifMethods ? values.amountCash : values.amount;

  return (
    <PaymentType
      name={name}
      onSelect={handleMethodChange}
      initialValues={cashInitialValues}
      hasUpdateDifMetods={hasUpdateDifMethods}
      title="PAGO EN EFECTIVO"
    >
      <div className="row">
        {hasUpdateDifMethods && (
          <div className="col mt-3">
            <NumberInput
              id="reservations_payment_cashMethod_amountCash"
              decimals={2}
              max={9_999_999}
              label="Monto en Efectivo"
              placeholder="Monto en Efectivo"
              name="amountCash"
              disabled={!hasUpdateDifMethods}
            />
          </div>
        )}
        <div className="col-4 mt-5">
          <CashBill
            folio={billDetails?.folio}
            customerFullName={billDetails?.customerFullName}
            concept={billDetails?.concept}
            total={totalCash}
            onlyPrint
          />
        </div>
      </div>
    </PaymentType>
  );
};

CashMethod.propTypes = {
  name: PropTypes.string,
  onSelect: PropTypes.func,
  hasUpdateDifMethods: PropTypes.bool,
};

CashMethod.defaultProps = {
  name: 'cash',
};

export default CashMethod;
