import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import {
  MaskedInput as Input,
  FileInput,
  NumberInput,
} from '@casanova/casanova-common';
import { DatePickerV2 } from 'components';
import { PaymentType } from '@reservations/components';
import { alphaMask, hourMask } from 'utils/masks';
import { depositInitialValues } from './config';
import useMultiPayment from '../hooks/useMultiPayment';

const DepositMethod = ({ name }) => {
  const { values } = useFormikContext();
  const { handleMethodChange, hasUpdateDifMethods } = useMultiPayment();

  return (
    <PaymentType
      name={name}
      onSelect={handleMethodChange}
      initialValues={depositInitialValues}
      title="DEPÓSITO BANCARIO"
      hasUpdateDifMetods={hasUpdateDifMethods}
    >
      <div className="row">
        <div className="col-6">
          <Input
            id="reservations_payment_depositMethod_bankAuthorization"
            mask={alphaMask(20)}
            maskPlaceholder=""
            label="Referencia*"
            placeholder="Referencia*"
            name="bankAuthorization"
          />
        </div>
        <div className="col-6">
          <DatePickerV2
            id="reservations_payment_depositMethod_paymentDate"
            label="Fecha*"
            name="paymentDate"
            placeholder="Fecha*"
            disabled
          />
        </div>
        <div className="col-6">
          <Input
            id="reservations_payment_depositMethod_paymentTime"
            mask={hourMask}
            maskPlaceholder=""
            label="Hora*"
            placeholder="Hora*"
            name="paymentTime"
            disabled
          />
        </div>
        <div className="col-md-6">
          <FileInput
            id="reservations_payment_depositMethod_bankVoucher"
            url={values.bankVoucher}
            displayText={values.bankVoucher}
            type="file"
            label="Comprobante*"
            placeholder="Comprobante*"
            name="bankVoucher"
            showIcon={false}
          />
        </div>
        <div className="col-6">
          {hasUpdateDifMethods && (
            <NumberInput
              id="reservations_payment_depositMethod_amountDeposit"
              decimals={2}
              max={9_999_999}
              label="Monto en Deposito"
              placeholder="Monto en Deposito"
              name="amountDeposit"
              disabled={!hasUpdateDifMethods}
            />
          )}
        </div>
      </div>
    </PaymentType>
  );
};

DepositMethod.propTypes = {
  name: PropTypes.string,
  onSelect: PropTypes.func,
  hasUpdateDifMethods: PropTypes.bool,
};

DepositMethod.defaultProps = {
  name: 'deposit',
};

export default DepositMethod;
