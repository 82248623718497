import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import moment from 'moment';
import _get from 'lodash/get';

import {
  DatePickerV2 as DatePicker,
  Select,
  Button,
  Input,
  SearchableSelect,
} from '@casanova/casanova-common';

import { SelectWithOptions } from 'components';

import {
  fieldSearch,
  transformForSelect,
} from '@casanova/casanova-common/lib/utils/transformHelpers';
import {
  formattedDateToPickerV2,
  getPrevYear,
  getNextYear,
  timeZone,
  YYYY_MM_DD,
} from '@casanova/casanova-common/lib/utils/date';
import i18n from '@i18n';

import { CheckBox } from '@reports/components';
import {
  sendCustomRequest,
  sendRequest,
} from '@casanova/casanova-common/lib/utils/service';
import { REPORTS_NO_FILTERS_TO_SHOW } from '../../common/constants';

const MIN_DATE = getPrevYear({ years: 2 });
const MAX_DATE = formattedDateToPickerV2(
  moment().add(1, 'day').tz(timeZone).format(YYYY_MM_DD)
);

const getUnlimitedDate = () =>
  formattedDateToPickerV2(moment().add(100, 'years').format(YYYY_MM_DD));

const setTouchedValuesByReportType = {
  '38edfcf6-7ce3-4ec0-a33b-2e8cb49bdb68': {
    name: 'Contratos aperturados / cerrados / vencidos',
    MAX_DATE: getNextYear(),
  },
  '4e448203-4c07-4535-a159-629f5b26c8e0': {
    name: 'Reporte de reservación',
    MAX_DATE: getUnlimitedDate(),
  },
  'cffa7a6b-e405-4945-85f2-d9827f9f532d': {
    name: 'Devoluciones de vehículos',
    MAX_DATE: getNextYear(),
  },
  'bf17b12c-79b8-416d-9e89-17f792b1e7cc': {
    name: 'Traslados llegadas / salidas',
    MAX_DATE: getNextYear(),
  },
  'ff5b204d-1282-4ebd-a6db-d177d957c22d': {
    name: 'Servicios por vehículo',
    MAX_DATE: getNextYear(),
  },
};

export default function SearchReportForm({
  typesOfReports,
  getTypesOfReports,
  setHasResetList,
  fetchCatalog,
  searchTenderCustomer,
}) {
  const {
    handleSubmit,
    resetForm,
    setFieldValue,
    setFieldError,
    setFieldTouched,
  } = useFormikContext();
  const [listFields, setListFields] = useState([]);
  const [secondDatePickerOrder, setSecondDatePickerOrder] = useState('');
  const [dynamicMinDate, setDynamicMinDate] = useState();
  const [chooseReportType, setChooseReportType] = useState(false);
  const [datePickerFields, setDatePickerFields] = useState([]);
  const [maximumDate, setMaximumDate] = useState(MAX_DATE);
  const [selectedClient, setSelectedClient] = useState(null);
  const [customerContracts, setCustomerContracts] = useState([]);
  const [branches, setBranches] = useState([]);

  const handleSelectCustomer = useCallback(
    (plate) => {
      setFieldTouched('customer', true);
      setFieldValue('customer', plate);
      setSelectedClient(plate);
    },
    [setFieldValue, setFieldTouched]
  );

  const handleSearchTenderCustomer = useCallback(
    (query) => {
      if (query && query.length > 2) {
        setFieldValue('customer', '');
        searchTenderCustomer(query);
      }
    },
    [setFieldValue, searchTenderCustomer]
  );

  const handleBlurCustomer = useCallback(() => {
    setFieldTouched(fieldSearch('customer'), true);
    setFieldTouched('customer', true);
  }, [setFieldTouched]);

  const renderFields = (e) => {
    const options = typesOfReports.find((item) => item.uuid === e.target.value);
    const dateFields =
      options &&
      options.params &&
      options.params.filter((item) => item.type === 'DATE');
    const secondDatePicker = _get(dateFields, '1.order', null);

    setSecondDatePickerOrder(secondDatePicker);
    setListFields(options.params);
    resetForm();
    setFieldValue('reportType', e.target.value);

    options.params.forEach((field) => setFieldValue(`key${field.order}`, ''));

    setMaximumDate(
      setTouchedValuesByReportType[e.target.value]?.MAX_DATE ?? MAX_DATE
    );
    setChooseReportType(true);
    setDatePickerFields(dateFields);
  };

  useEffect(() => {
    getTypesOfReports();
  }, [getTypesOfReports, typesOfReports]);

  useEffect(() => {
    if (listFields.length > 0) {
      listFields.forEach(
        (field) => field.path && fetchCatalog(JSON.parse(field.path))
      );
    }
  }, [listFields]);

  const fetchCustomerContracts = async () => {
    const contracts = await sendRequest(
      `pricequotes/v1/virtual-contracts?juridicalPerson=${selectedClient}&size=10000`,
      {}
    );
    contracts.data.content.map((contract) => {
      contract.label = `Contrato no. ${contract.noContrato}`;
      contract.value = contract.uuid;
      return contract;
    });
    // insert all contracts option at the beginning
    contracts.data.content.unshift({
      label: 'Todos los contratos',
      value: `*${selectedClient}`,
    });
    setCustomerContracts(contracts.data.content);
    setFieldValue('key1', contracts.data.content[0].value);
  };

  const fetchBranchesCatalog = async () => {
    const branches = await sendCustomRequest(
      'catalog/v1/branches?sort=name&&isEnabled=true',
      {},
      undefined,
      'get'
    );
    console.log(branches);
    branches.data?._embedded?.branches.map((branch) => {
      branch.label = branch.name;
      branch.value = branch.uuid;
      return branch;
    });
    branches.data?._embedded?.branches?.unshift({
      label: 'Todas las sucursales',
      value: '00000000-0000-0000-0000-000000000000',
    });
    setBranches(branches.data?._embedded?.branches);
  };

  useEffect(() => {
    if (selectedClient) {
      fetchCustomerContracts();
    }
  }, [selectedClient]);

  useEffect(() => {
    fetchBranchesCatalog();
  }, []);

  return (
    <div className="row">
      <div className="col-md-4">
        <Select
          label={i18n('REPORTS__SEARCH_REPORT_FORM__REPORT_TYPES', ['*'])}
          placeholder={i18n('REPORTS__SEARCH_REPORT_FORM__REPORT_TYPES', ['*'])}
          name="reportType"
          options={transformForSelect(typesOfReports, 'uuid', 'name')}
          onChange={(e) => {
            renderFields(e);
          }}
        />
      </div>

      {listFields.length > 0
        ? listFields.map((item) => {
            const fieldToShow = [];

            switch (item.type) {
              case 'NUMBER':
                fieldToShow.push(
                  <div className="col-md-4" key={`key_container_${item.order}`}>
                    <Input
                      label={item.name}
                      placeholder={item.name}
                      name={`key${item.order}`}
                    />
                  </div>
                );
                break;
              case 'DATE':
                fieldToShow.push(
                  <div className="col-md-4" key={`key_container_${item.order}`}>
                    <DatePicker
                      label={item.name}
                      placeholder={item.name}
                      name={`key${item.order}`}
                      minimumDate={
                        datePickerFields.length === 2 &&
                        secondDatePickerOrder === item.order
                          ? dynamicMinDate
                          : MIN_DATE
                      }
                      maximumDate={maximumDate}
                      onChange={(value) => {
                        if (
                          datePickerFields.length === 2 &&
                          secondDatePickerOrder !== item.order
                        ) {
                          setFieldValue(`key${secondDatePickerOrder}`, '');
                          setDynamicMinDate(formattedDateToPickerV2(value));
                        }
                      }}
                      resetDate
                      onResetDate={() => {
                        setFieldValue(`key${item.order}`, '');
                      }}
                    />
                  </div>
                );
                break;
              case 'STRING':
                fieldToShow.push(
                  <div className="col-md-4" key={`key_container_${item.order}`}>
                    <Input
                      type="text"
                      label={item.name}
                      placeholder={item.name}
                      name={`key${item.order}`}
                    />
                  </div>
                );
                break;
              case 'DROPDOWN':
                fieldToShow.push(
                  <div className="col-md-4" key={`key_container_${item.order}`}>
                    <SelectWithOptions
                      label={item.name}
                      placeholder={item.name}
                      name={`key${item.order}`}
                      options={`commons.${JSON.parse(item.path).catalogName}`}
                      className="m-0 select"
                    />
                  </div>
                );
                break;
              case 'CHECKBOX':
                fieldToShow.push(
                  <div
                    className="col-md-4 d-flex align-items-end"
                    key={`key_container_${item.order}`}
                  >
                    <CheckBox name={`key${item.order}`} label={item.name} />
                  </div>
                );
                break;
              case 'CLIENT_VIRTUAL_CONTRACTS':
                fieldToShow.push(
                  <div className="col-md-4" key={`key_container_${item.order}`}>
                    <SearchableSelect
                      onChange={handleSelectCustomer}
                      onBlur={handleBlurCustomer}
                      name="customer"
                      label={i18n('CONTRACTS__CONTRACT_DETAIL__CUSTOMER', [
                        '*',
                      ])}
                      placeholder={i18n(
                        'CONTRACTS__CONTRACT_DETAIL__CUSTOMER',
                        ['*']
                      )}
                      options="customers.tenderCustomers"
                      onSearching={handleSearchTenderCustomer}
                      icon="svg-icon-search"
                      onRemove={() => {
                        setFieldValue('customer', '');
                        setFieldValue('customer_search', '');
                        setFieldValue('customer_lastSearch', '');
                        setFieldTouched('customer', true);
                        setFieldTouched('customer_search', true);
                        setFieldTouched('customer_lastSearch', true);
                        setSelectedClient(null);
                      }}
                    />
                    <Select
                      label={item.name}
                      placeholder={item.name}
                      name={`key${item.order}`}
                      options={customerContracts}
                      className="m-0 select"
                    />
                  </div>
                );
                break;
              case 'BRANCHES_SELECT':
                fieldToShow.push(
                  <div className="col-md-4" key={`key_container_${item.order}`}>
                    <Select
                      label={item.name}
                      placeholder={item.name}
                      name={`key${item.order}`}
                      options={branches}
                      className="m-0 select"
                    />
                  </div>
                );
                break;
              default:
                break;
            }

            return fieldToShow;
          })
        : chooseReportType && (
            <div className="col-md-4 mt-5">
              <b>{REPORTS_NO_FILTERS_TO_SHOW}</b>
            </div>
          )}

      <div className="col-md-3 offset-md-9">
        <Button
          block
          type="submit"
          onClick={() => {
            setHasResetList(false);
            handleSubmit();
          }}
        >
          {i18n('COMMONS__SEARCH')}
        </Button>
      </div>
    </div>
  );
}

SearchReportForm.propTypes = {
  typesOfReports: PropTypes.arrayOf(PropTypes.object).isRequired,
  getTypesOfReports: PropTypes.func.isRequired,
  setHasResetList: PropTypes.func.isRequired,
};
