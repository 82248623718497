import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { WaitingModal } from '@reservations/components';
import {
  MaskedInput as Input,
  FileInput,
  DatePickerV2,
  ConnectedSelect,
  Section,
  SmallCheckbox as Checkbox,
  Icons,
  Button,
  ActionModal,
} from '@casanova/casanova-common';
import { base64DataToFile, acceptFiles } from 'utils/files';
import { findUuidByProperty } from 'utils/catalogs';

import i18n from '@i18n';
import { alphaMask } from '@casanova/casanova-common/lib/utils/masks';

import { EMISSION_AND_VALIDITY_VALIDATIONS } from '@customers/common/validations';
import { FORM_ACTIONS } from 'utils/constants/formActions';
import { INNCODE_SCORE } from '@customers/common/constants';
import _noop from 'lodash/noop';
import { VERIFICATION_TYPES, PERMANENT_VALIDITY } from './constants';

import config, { initialValues as driverLivenseInitialValues } from './config';
import { customerLicenseInnCodeInfoFormatter } from './formatter';

const { EMISSION, VALIDITY, ANTIQUITY } = EMISSION_AND_VALIDITY_VALIDATIONS;

export default function DriverLicense({
  action = FORM_ACTIONS.VIEW,
  isForeigner,
  innCodeScore,
  innCodeImages,
  licenseInnCodeData,
  licenseValidation = {},
  getLinkToValidateLicense = _noop,
  urlInnCodeInfo = {},
  innCodeInfo = {},
  flowInnCodeCompleted = true,
  closeResponseGetLinkToValidateLicense = _noop,
  closeResponseValidateLicense = _noop,
  validateLicense = _noop,
  nacionalities,
  statesList,
}) {
  const [hasChangedPermanent, setHasChangedPermanent] = useState(false);
  const [resetFileInput,setResetFileInput] = useState(false);
  const {
    values,
    initialValues,
    setValues,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext();
  const [validateLicenseModalData, setValidateLicenseModalData] = useState({});

  const { isPermanentLicense = false } = values;

  const handleOnChangeLicenseType = (event) => {
    setValues({
      ...values,
      ...config.initialValues,
      licenseType: event.target.value,
    });
    setResetFileInput(true);
  };

  const handlePermanentLicenseChange = useCallback(
    (permanentLicense) => {
      setHasChangedPermanent(true);
      if (permanentLicense) {
        setFieldValue('validityDriverLicense', PERMANENT_VALIDITY);
      } else {
        setFieldValue('validityDriverLicense', '');
      }

      setTimeout(() => {
        setFieldTouched('validityDriverLicense', true);
        setFieldTouched('licenseFrontSide', true);
        setFieldTouched('licenseBackSide', true);
      }, 1);
    },
    [setFieldValue, setFieldTouched]
  );

  const disabledInput = action === 'view';

  const hasFieldWithValue = useMemo(() => {
    const filterValues = ([key]) =>
      driverLivenseInitialValues[key] !== undefined;
    const someFieldWithValue = ([, value]) => Boolean(value);

    const currentVisaValues = Object.entries(values).filter(filterValues);
    return currentVisaValues.some(someFieldWithValue);
  }, [values]);

  const cleanLicenseButton = useCallback(() => {
    setValues({ ...values, ...driverLivenseInitialValues });
    setResetFileInput(true);
  }, [setValues, values]);

  const innCodeDisableFields =
    innCodeScore > INNCODE_SCORE.INE.OK &&
    Object.keys(licenseInnCodeData).length;

  const handleActionVerificationAlert = () => {
    const { token } = urlInnCodeInfo;
    validateLicense({
      token,
    });
  };

  useEffect(() => {
    if (innCodeInfo.data?.ocrData?.license?.classes) {
      // setValidateLicenseModalData(getScoreObject(INNCODE_SCORE.INE.OK + 1)); // TODO: Change to innCodeScore
      // if (innCodeInfo.score > INNCODE_SCORE.INE.OK) {
      setLicenseInncodeData();
      // }
      setValidateLicenseModalData({
        title: 'Información verificada',
        icon: <Icons.IcoPositivoMini />,
        message: `La información de la licencia fue verificada correctamente`,
      });
    } else if (innCodeInfo.flowCompleted) {
      setValidateLicenseModalData({
        title: 'Error',
        icon: <Icons.ErrorBlack />,
        message:
          'Hubo un error al obtener la información de la licencia, por favor intenta de nuevo',
      });
    }
  }, [innCodeInfo]);

  const setLicenseInncodeData = () => {
    const valuesLicenseInnCode = customerLicenseInnCodeInfoFormatter(
      innCodeInfo.data.ocrData
    );
    Object.keys(valuesLicenseInnCode).forEach((key) => {
      if (key === 'nationality') {
        setFieldValue(
          key,
          findUuidByProperty(nacionalities, valuesLicenseInnCode[key], 'name')
        );
        return;
      }
      if (key === 'emissionState') {
        setFieldValue(
          key,
          findUuidByProperty(statesList, valuesLicenseInnCode[key], 'name')
        );
        return;
      }
      setFieldValue(key, valuesLicenseInnCode[key]);
    });
    if (innCodeInfo.images && Object.keys(innCodeInfo.images).length) {
      const officialFiles = {
        licenseFrontSide: base64DataToFile(
          innCodeInfo.images.croppedFrontID,
          'croppedFrontID.jpg',
          'image/jpeg'
        ),
        licenseBackSide: base64DataToFile(
          innCodeInfo.images.croppedBackID,
          'croppedBackID.jpg',
          'image/jpeg'
        ),
      };
      Object.keys(officialFiles).forEach((key) =>
        setFieldValue(key, officialFiles[key])
      );
    }
  };

  // const getScoreObject = (score) => {
  //   if (score === undefined) return {};
  //   const scoreMapping = {
  //     fail: {
  //       title: 'No verificado',
  //       icon: <Icons.ErrorBlack />,
  //       action: 'retry',
  //       message: `No cumples con el score suficiente para verificar, score obtenido: ${score}`,
  //     },
  //     warn: {
  //       title: 'Score bajo',
  //       icon: <Icons.UserAlert />,
  //       action: 'review',
  //       message: `Vuelve a intentar la verificación, score obtenido: ${score}`,
  //     },
  //     ok: {
  //       title: 'Información verificada',
  //       icon: <Icons.IcoPositivoMini />,
  //       action: 'proceed',
  //       message: `Score obtenido: ${score}`,
  //     },
  //   };

  //   return score < INNCODE_SCORE.INE.WARN
  //     ? scoreMapping.fail
  //     : score <= INNCODE_SCORE.INE.OK
  //     ? scoreMapping.warn
  //     : scoreMapping.ok;
  // };

  return (
    <>
      {/* <ActionModal
        open={identityLicenseValidation.openValidationResponse}
        icon={identityLicenseValidation.error ? <Icons.ErrorBlack /> : ''}
        closeButton
        // onClose={closeResponseGetLinkToValidateIdentity}
        // onAction={
        //   identityLicenseValidation.error
        //     ? closeResponseGetLinkToValidateIdentity
        //     : handleActionVerificationAlert
        // }
        modalType="confirmation"
        title={
          identityLicenseValidation.error
            ? 'Error'
            : identityLicenseValidation.title
        }
        actionLabel={identityLicenseValidation.error ? 'Cerrar' : 'Continuar'}
        message={
          identityLicenseValidation.error ? (
            `Hubo un error al obtener el enlace 
                    ${identityLicenseValidation?.errorMessage}`
          ) : (
            <a
              href={identityLicenseValidation?.validationLink?.url ?? ''}
              className="text-right text-secondary text-decoration-none"
              rel="noopener norefer noreferrer"
              target="_blank"
            >
              Link de verificación
            </a>
          )
        }
      />
      <WaitingModal
        title="Verificando información..."
        open={identityLicenseValidation.loading}
      />
      <ActionModal
        open={identityLicenseValidation.openValidationResponse}
        icon={identityLicenseValidation?.error ? <Icons.ErrorBlack /> : ''}
        closeButton
        // onClose={closeResponseValidateIdentity}
        // onAction={closeResponseValidateIdentity}
        modalType="confirmation"
        title={identityLicenseValidation?.error ? 'Error' : 'Validación'}
        actionLabel="Cerrar"
        message={
          identityLicenseValidation?.error
            ? `Hubo un error al validar la información 
                    ${identityLicenseValidation?.errorMessage}`
            : 'La información fue validada correctamente'
        }
      /> */}

      <ActionModal
        open={licenseValidation.open}
        icon={licenseValidation.error ? <Icons.ErrorBlack /> : ''}
        closeButton
        onClose={closeResponseGetLinkToValidateLicense}
        onAction={
          licenseValidation.error
            ? closeResponseGetLinkToValidateLicense
            : handleActionVerificationAlert
        }
        modalType="confirmation"
        title={licenseValidation.error ? 'Error' : 'Verificación de licencia'}
        actionLabel={licenseValidation.error ? 'Cerrar' : 'Continuar'}
        message={
          licenseValidation.error ? (
            `Hubo un error al obtener el enlace 
                ${licenseValidation?.errorMessage}`
          ) : (
            <a
              href={urlInnCodeInfo?.url ?? ''}
              className="text-right text-secondary text-decoration-none"
              rel="noopener norefer noreferrer"
              target="_blank"
            >
              Link de verificación
            </a>
          )
        }
      />
      <WaitingModal
        title="Verificando información..."
        open={!flowInnCodeCompleted}
      />
      <ActionModal
        open={licenseValidation.openValidationResponse}
        // icon={<Icons.IcoPositivoMini />}
        icon={validateLicenseModalData.icon}
        closeButton
        onClose={closeResponseValidateLicense}
        onAction={closeResponseValidateLicense}
        modalType="confirmation"
        // title="Información verificada"
        title={validateLicenseModalData.title}
        actionLabel="Cerrar"
        message={validateLicenseModalData.message}
      />
      <Section
        title="LICENCIA DE CONDUCIR"
        right={
          <div className="d-flex align-items-center justify-content-between -ml-5">
            <div className="col-10 -ml-5">
              <br />
              <Button
                block
                onClick={() => {
                  getLinkToValidateLicense(VERIFICATION_TYPES.CASANOVA_LICENSE);
                }}
              >
                {i18n('CUSTOMERS__VALIDATE_LICENSE__BUTTON')}
              </Button>
            </div>
            <Checkbox
              name="isPermanentLicense"
              label="Permanente"
              onChange={handlePermanentLicenseChange}
            />
          </div>
        }
      >
        <div className="row">
          <div className="col-md-4">
            <ConnectedSelect
              label={`Tipo de licencia ${
                hasFieldWithValue || isForeigner ? '*' : ''
              }`}
              name="licenseType"
              options="commons.driverLicenseTypeEntities"
              onChange={handleOnChangeLicenseType}
              disabled={disabledInput || innCodeDisableFields}
            />
          </div>
          <div className="col-4">
            <Input
              mask={alphaMask(20)}
              maskPlaceholder=""
              label={`Número de licencia${
                hasFieldWithValue || isForeigner ? '*' : ''
              }`}
              placeholder={`Número de licencia${
                hasFieldWithValue || isForeigner ? '*' : ''
              }`}
              name="licenseNumber"
              disabled={disabledInput || innCodeDisableFields}
              upperCase
            />
          </div>
          <div className="col-md-4">
            <DatePickerV2
              initialValue={values.antiquity}
              placeholder={`Antigüedad${
                hasFieldWithValue || isForeigner ? '*' : ''
              }`}
              name="antiquity"
              label={`Antigüedad${hasFieldWithValue || isForeigner ? '*' : ''}`}
              disabled={disabledInput || innCodeDisableFields}
              minimumDate={ANTIQUITY.MIN_DATE}
              maximumDate={ANTIQUITY.MAX_DATE}
              formatDateValue
            />
          </div>
          <div className="col-md-4">
            <DatePickerV2
              initialValue={values.expedition}
              label="EMISIÓN"
              placeholder="EMISIÓN"
              name="expedition"
              disabled={disabledInput || innCodeDisableFields}
              minimumDate={EMISSION.MIN_DATE}
              maximumDate={EMISSION.MAX_DATE}
              formatDateValue
            />
          </div>
          {!isPermanentLicense && (
            <div className="col-md-4">
              <DatePickerV2
                initialValue={
                  !hasChangedPermanent
                    ? values.validityDriverLicense
                    : undefined
                }
                label={`Vigencia${hasFieldWithValue || isForeigner ? '*' : ''}`}
                placeholder={`Vigencia${
                  hasFieldWithValue || isForeigner ? '*' : ''
                }`}
                name="validityDriverLicense"
                disabled={disabledInput || innCodeDisableFields}
                minimumDate={VALIDITY.MIN_DATE}
                maximumDate={VALIDITY.MAX_DATE}
                formatDateValue
              />
            </div>
          )}
          <div className="col-md-4">
            <ConnectedSelect
              disabled={disabledInput}
              label={`Nacionalidad${
                hasFieldWithValue || isForeigner ? '*' : ''
              }`}
              name="nationality"
              options="commons.nationalities"
            />
          </div>
          {!isForeigner && (
            <div className="col-md-4">
              <ConnectedSelect
                disabled={disabledInput}
                label={`Estado de emisión${
                  hasFieldWithValue || isForeigner ? '*' : ''
                }`}
                name="emissionState"
                options="commons.states"
              />
            </div>
          )}
          <div className="col-12 col-md-4">
            <FileInput
              key={resetFileInput ? 'reset' : 'not-reset'}
              url={action !== 'add' ? values.licenseFrontSideUrl : undefined}
              displayText={values.licenseFrontSide}
              type="file"
              label={`Frontal de la licencia${
                hasFieldWithValue || isForeigner ? '*' : ''
              }`}
              placeholder={`Frontal de la licencia${
                hasFieldWithValue || isForeigner ? '*' : ''
              }`}
              name="licenseFrontSide"
              accept={acceptFiles}
              disabled={
                disabledInput ||
                (innCodeDisableFields && innCodeImages.croppedFrontID)
              }
              onChange={() => setResetFileInput(false)}
            />
          </div>
          <div className="col-12 col-md-4">
            <FileInput
              key={resetFileInput ? 'reset' : 'not-reset'}
              url={action !== 'add' ? values.licenseBackSideUrl : undefined}
              displayText={values.licenseBackSide}
              type="file"
              label={`Trasera de la licencia${
                hasFieldWithValue || isForeigner ? '*' : ''
              }`}
              placeholder={`Trasera de la licencia${
                hasFieldWithValue || isForeigner ? '*' : ''
              }`}
              name="licenseBackSide"
              accept={acceptFiles}
              disabled={
                disabledInput ||
                (innCodeDisableFields && innCodeImages.croppedFrontID)
              }
              onChange={() => setResetFileInput(false)}
            />
          </div>
          <div className="col-md-4 d-flex align-items-center">
            {hasFieldWithValue && action == 'add' && !innCodeDisableFields && (
              <span
                onClick={cleanLicenseButton}
                onKeyPress={cleanLicenseButton}
                className="removable svg-icon svg-icon-center"
                role="button"
                tabIndex="0"
              >
                <Icons.CloseGroup width="100%" height="100%" />
              </span>
            )}
          </div>
        </div>
      </Section>
    </>
  );
}

DriverLicense.propTypes = {
  action: PropTypes.string,
};
